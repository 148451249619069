import React from 'react';
import ReactDOM from 'react-dom';

import BTICountryDashboard from './BTICountryDashboard';


export const initBTICountryDashboard = (container) => {
  container.classList.add('bti-country-dashboard--initialized');

  const configUrl = container.getAttribute('data-bti-country-dashboard-config-url');
  const country = container.getAttribute('data-bti-country-dashboard-country');

  ReactDOM.render((
    <BTICountryDashboard
      configUrl={configUrl}
      country={country}
    />
  ), container);
};

export const initBTICountryDashboards = () => {
  document.querySelectorAll('.bti-country-dashboard:not(.bti-country-dashboard--initialized)').forEach(initBTICountryDashboard);
};

initBTICountryDashboards();

document.querySelectorAll('.collapsible:not(.collapsible--react)').forEach((wrap) => {
  const toggleButton = wrap.querySelector('.collapsible__toggle');
  const toggle = () => {
    if (wrap.classList.contains('collapsible--collapsed')) {
      wrap.classList.add('collapsible--expanded');
      wrap.classList.remove('collapsible--collapsed');
    } else {
      wrap.classList.add('collapsible--collapsed');
      wrap.classList.remove('collapsible--expanded');
    }
    const ResizeEvent = new CustomEvent('resize');
    window.dispatchEvent(ResizeEvent);
  };
  toggleButton.addEventListener('click', toggle);
});

import React from 'react';
import classNames from 'classnames';
import Icon from '../../../../_particles/icon/Icon';

const GoButton = ({ href, title, variant }) => (
  <a
    className={classNames([
      'bti-map-go-button',
      `bti-map-go-button--${variant || 'default'}`,
    ])}
    href={href}
  >
    {title}
    <Icon icon="hook-right" className="bti-map-go-button__icon" />
  </a>
);

export default GoButton;

import React from 'react';
import Icon from '../../_particles/icon/Icon';

const BTIDashboardInfoIcon = ({ tooltipId, event }) => (
  <button className="bti-dashboard-info-icon" data-tip data-for={tooltipId} data-event={event}>
    <Icon icon="info" />
  </button>
);

export default BTIDashboardInfoIcon;

import React from 'react';
import classNames from 'classnames';
import DimensionDonut from '../DimensionDonut';

const DimensionDonutButtonWrap = ({
  active,
  color,
  onClick,
  children,
}) => {
  if (onClick) {
    return <button
      className={classNames({
        'bti-country-dashboard__dimension-donut-button': true,
        'bti-country-dashboard__dimension-donut-button--button': true,
        'bti-country-dashboard__dimension-donut-button--active': active,
      })}
      style={{ color }}
      onClick={onClick}
    >
      {children}
    </button>;
  }
  return <div className="bti-country-dashboard__dimension-donut-button">{children}</div>;
};


const DimensionDonutButton = ({
  active,
  categoryName,
  color,
  dimensionTitle,
  onClick,
  value,
}) => (
  <DimensionDonutButtonWrap active={active} color={color} onClick={onClick}>
    <div className="bti-country-dashboard__dimension-donut-button__donut-container">

      <DimensionDonut
        className={classNames({
          'bti-country-dashboard__dimension-donut-button__donut': true,
          'bti-country-dashboard__dimension-donut-button__donut--active': active,
        })}
        classPrefix="bti-country-dashboard__dimension-donut-button__donut"
        color={color}
        displayValue
        label={categoryName}
        value={value}
      />

    </div>
    {dimensionTitle && (
      <div className="bti-country-dashboard__dimension-donut-button__title">
        {dimensionTitle}
      </div>
    )}
  </DimensionDonutButtonWrap>
);

export default DimensionDonutButton;

// @flow
import * as React from 'react';
import SocialSharing from '../../molecules/social-sharing/SocialSharing';
import Dialog from '../../_particles/dialog/Dialog';
import { t } from '../../../javascripts/utils/withTranslation';

type Props = {
  onClose: () => void,
  dialogParent: Element,
  url: string,
  title: string,
  twitterAccount: string,
  services: array,
};

const DialogShare = (props: Props) => (
  <Dialog
    className="share-dialog"
    onClose={props.onClose}
    dialogParent={props.dialogParent}
    closeButton={true}
    title={t('Teilen via...')}
  >
    <div className="share-dialog__content">
      <SocialSharing
        url={props.url}
        title={props.title}
        twitterAccount={props.twitterAccount}
        services={props.services}
      />
    </div>
  </Dialog>
);

export default DialogShare;

import React from 'react';
import { t } from '../../../../../javascripts/utils/withTranslation';
import Icon from '../../../../_particles/icon/Icon';

const DimensionBackButton = ({ onClick }) => (
  <button
    className="bti-country-dashboard__dimension-details-back-button"
    onClick={onClick}
  >
    <Icon icon="hook-left" className="bti-country-dashboard__dimension-details-back-button__icon" />
    {t('Zurück zur Übersicht')}
  </button>
);

export default DimensionBackButton;

import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import unvisibleFocus from 'javascripts/utils/unvisible-focus';
import { generateIcon as icon } from 'components/_particles/icon/Icon';

export default class NavigationDesktop {
  constructor($navigation) {
    this.$navigation = $navigation;

    // Events
    this.onClickBinded = this.onClick.bind(this);
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);
    this.onMousedownBinded = this.onMousedown.bind(this);
    this.onFocusoutBinded = this.onFocusout.bind(this);
    this.onFlyoutCloseBinded = this.onFlyoutClose.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);
  }

  init() {
    // Click events on items
    this.$navigation.querySelectorAll('.simple-header__navigation-item-link, .simple-header__navigation-submenu-item-link').forEach(($link) => {
      const $item = $link.parentNode;
      const $flyoutOrSubmenu = $item.querySelector('.simple-header__navigation-flyout, .simple-header__navigation-submenu');

      if ($flyoutOrSubmenu) {
        // Set ARIA attributes
        $link.setAttribute('aria-haspopup', 'true');
        $flyoutOrSubmenu.setAttribute('aria-hidden', 'true');
        $flyoutOrSubmenu.setAttribute('aria-labelledby', $link.getAttribute('id'));

        // Add click event
        $link.addEventListener('mousedown', this.onMousedownBinded);
        $link.addEventListener('click', this.onClickBinded);
      }
    });

    // Add close buttons and ESC keydown event
    this.$navigation.querySelectorAll('.simple-header__navigation-flyout').forEach(($flyout) => {
      // Generate close button
      const $closeButton = h(
        'button.icon-link.icon-link--small.simple-header__navigation-close',
        {
          ype: 'button',
          onclick: this.onFlyoutCloseBinded,
        },
        h('span.icon-link__button', icon({
          icon: 'close',
          className: 'icon-link__icon',
        })),
        h(
          'span.icon-link__text',
          'Schließen',
        ),
      );

      // Append to flyout
      $flyout.appendChild(h('.simple-header__navigation-close-wrap', $closeButton));

      // Add keydown event to flyout
      $flyout.addEventListener('keydown', this.onKeydownBinded);
    });

    // Losing focus event
    this.$navigation.addEventListener('focusout', this.onFocusoutBinded);

    // On outside click
    document.addEventListener('click', this.onOutsideClickBinded);
  }

  deinit() {
    // Reset navigation
    this.resetNavigation(null, null, true);

    // Click events on items
    this.$navigation.querySelectorAll('.simple-header__navigation-item-link, .simple-header__navigation-submenu-item-link').forEach(($link) => {
      const $item = $link.parentNode;
      const $flyoutOrSubmenu = $item.querySelector('.simple-header__navigation-flyout, .simple-header__navigation-submenu');

      if ($flyoutOrSubmenu) {
        // Remove ARIA attributes
        $link.removeAttribute('aria-haspopup');
        $flyoutOrSubmenu.removeAttribute('aria-hidden');
        $flyoutOrSubmenu.removeAttribute('aria-labelledby');

        // Remove click event
        $link.removeEventListener('mousedown', this.onMousedownBinded);
        $link.removeEventListener('click', this.onClickBinded);
      }
    });

    // Remove close buttons and ESC keydown event
    this.$navigation.querySelectorAll('.simple-header__navigation-flyout').forEach(($flyout) => {
      // Close buttons
      $flyout.querySelectorAll('.simple-header__navigation-close').forEach(($button) => {
        $button.parentNode.removeChild($button);
      });

      // Remove keydown event
      $flyout.removeEventListener('keydown', this.onKeydownBinded);
    });

    // Losing focus event
    this.$navigation.removeEventListener('focusout', this.onFocusoutBinded);

    // On outside click
    document.removeEventListener('click', this.onOutsideClickBinded);
  }

  resetNavigation($flyoutOrSubmenu = null, $link = null, isFlyout = false) {
    const classes = [
      'simple-header__navigation-submenu--current',
      'simple-header__navigation-submenu-item-link--open',
    ];

    if (isFlyout) {
      classes.push(
        'simple-header__navigation-flyout--open',
        'simple-header__navigation-item-link--open',
      );
    }

    const classList = classes.map(c => `.${c}`).join(', ');
    this.$navigation.querySelectorAll(classList).forEach(($el) => {
      if (!$flyoutOrSubmenu || !$link || ($el !== $flyoutOrSubmenu && $el !== $link)) {
        classes.forEach(c => $el.classList.remove(c));

        if ($el.hasAttribute('aria-hidden')) {
          $el.setAttribute('aria-hidden', 'true');
        }
      }
    });

    if (!$flyoutOrSubmenu || !$link) {
      this.$navigation.classList.remove('navigation--has-open-flyout');
    }
  }

  onMousedown(event) {
    // Prevent setting focus on element by click
    event.preventDefault();
  }

  onClick(event) {
    const $link = event.currentTarget;
    const $flyoutOrSubmenu = $link.parentNode.querySelector('.simple-header__navigation-flyout, .simple-header__navigation-submenu');

    if ($flyoutOrSubmenu) {
      // Prevent link browsing
      event.preventDefault();

      // Is flyout?
      const isFlyout = $flyoutOrSubmenu.classList.contains('simple-header__navigation-flyout');

      // Close other flyouts/submenus
      this.resetNavigation($flyoutOrSubmenu, $link, isFlyout);

      // Toggle classes
      const open = $flyoutOrSubmenu.classList.toggle(isFlyout ? 'simple-header__navigation-flyout--open' : 'simple-header__navigation-submenu--current');
      $link.classList.toggle(isFlyout ? 'simple-header__navigation-item-link--open' : 'simple-header__navigation-submenu-item-link--open');

      // Toggle ARIA hidden attribute
      $flyoutOrSubmenu.setAttribute('aria-hidden', open ? 'false' : 'true');

      // Add/remove class to navigation
      if (open) {
        this.$navigation.classList.add('navigation--has-open-flyout');
      } else {
        this.$navigation.classList.remove('navigation--has-open-flyout');
      }

      // Open submenu if it contains active item
      if (open && isFlyout) {
        const $activeLink = $flyoutOrSubmenu.querySelector('.simple-header__navigation-submenu--3rd-level .simple-header__navigation-submenu-item-link--active');

        if ($activeLink) {
          $activeLink
            .closest('.simple-header__navigation-submenu')
            .closest('.simple-header__navigation-submenu-item')
            .querySelector('.simple-header__navigation-submenu-item-link')
            .click();
        }
      }
    }
  }

  // Close all flyouts/submenus, if link or flyout loses focus
  onFocusout(event) {
    if (event.relatedTarget && event.target.closest('.simple-header__navigation-item')) {
      const $newFocus = event.relatedTarget;
      const $item = event.target.closest('.simple-header__navigation-item');

      if (!$item.contains($newFocus) && $newFocus !== $item) {
        this.resetNavigation(null, null, true);
      }
    }
  }

  // Close all flyouts/submenus, if user clicks outside
  onOutsideClick(event) {
    const $openFlyout = this.$navigation.querySelector('.simple-header__navigation-flyout--open');

    if (event.target && $openFlyout && !this.$navigation.contains(event.target)) {
      this.resetNavigation(null, null, true);
    }
  }

  // Close all flyouts/submenus, if user clicks on the close button
  onFlyoutClose(event) {
    this.resetNavigation(null, null, true);

    // Set focus back on the link
    const $item = getTarget(event.target, '.simple-header__navigation-item');
    const $link = $item.querySelector('.simple-header__navigation-item-link');
    unvisibleFocus($link);
  }

  onKeydown(event) {
    if (event.keyCode === 27) {
      this.onFlyoutClose(event);
    }
  }
}

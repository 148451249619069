import React from 'react';
import ReactDOM from 'react-dom';
import BTIDashboardSelect from './BTIDashboardSelect';

document.querySelectorAll('select.bti-dashboard-select').forEach((select) => {
  const optionElements = select.querySelectorAll('option');
  const options = Array.from(optionElements).map(optionElement => ({
    value: optionElement.value,
    label: optionElement.innerText,
  }));

  ReactDOM.render((
    <BTIDashboardSelect options={options} />
  ), select.parentNode);
});

import React from 'react';
import BTIDashboardTooltip from '../../../../molecules/bti-dashboard-tooltip/BTIDashboardTooltip';

const CountriesInfoTooltip = ({ id, text, place }) => (
  <BTIDashboardTooltip
    id={id || 'countries-info-tooltip'}
    effect="solid"
    place={place}
  >
    <div className="bti-map-countries-info-tooltip">
      <div className="bti-map-countries-info-tooltip__body">
        {text}
      </div>
    </div>
  </BTIDashboardTooltip>
);

export default CountriesInfoTooltip;

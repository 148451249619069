import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from 'react';

import ReactTooltip from 'react-tooltip';

import { loadQueryData, storeQueryData } from '../../../javascripts/utils/query-data';
import { t } from '../../../javascripts/utils/withTranslation';

import Icon from '../../_particles/icon/Icon';
import Button from '../../atoms/button/Button';
import BTIDashboardInfoIcon from '../../molecules/bti-dashboard-info-icon/BTIDashboardInfoIcon';
import BTIDashboardSelect from '../../molecules/bti-dashboard-select/BTIDashboardSelect';
import BTIDashboardShareButton from '../../molecules/bti-dashboard-share-button/BTIDashboardShareButton';
import BTIDashboardSentence from '../../molecules/bti-dashboard-sentence/BTIDashboardSentence';

import CategorySelectButtonList from './components/CategorySelectButtonList';
import DimensionSelectButtonList from './components/DimensionSelectButtonList';
import MapImage from './components/MapImage';
import MapScroller from './components/MapScroller';
import SelectCategoryTooltip from './components/SelectCategoryTooltip';
import SelectDimensionTooltip from './components/SelectDimensionTooltip';

import { listReducer } from '../../../javascripts/bti/helpers/listReducer';

import { DIMENSION } from '../../../javascripts/bti/shared/constants';

import {
  useConfigAndData,
  useActiveDimensionTitle,
  useCountryAndRegionOptions,
  useDimensionTitles,
} from '../../../javascripts/bti/shared/hooks';


const HASH_MAP = {
  activeDimension: {
    short: 'd',
    type: 'string',
  },
  selectedCategories: {
    short: 'c',
    type: 'array',
  },
  selectedCategoriesBits: {
    short: 'cb',
    type: 'string',
  },
};

const BTIDashboardMap = ({ configUrl, region }) => {
  const initialHashData = useMemo(() => loadQueryData(HASH_MAP), []);
  const [activeDimension, _setActiveDimension] = useState(initialHashData.activeDimension);
  const [selectedCategories, dispatchSelectedCategories] = useReducer(
    listReducer,
    initialHashData.selectedCategories,
  );

  const setActiveDimension = (dimension) => {
    _setActiveDimension(dimension !== activeDimension ? dimension : null);
    dispatchSelectedCategories({ type: 'reset' });
  };

  /**
   * Load config and data
   */

  const dataKeys = ['indexMap', 'categories', 'countries', 'dimensions', 'index', 'regions', 'text'];
  if (region) {
    dataKeys.push('regionDashboard');
  }

  const [config, data] = useConfigAndData({
    keys: dataKeys,
    urlData: { regionIso3: region },
    configUrl,
  });

  /**
   * Event handlers
   */

  const handleCountryAndRegionSelectChange = useCallback((option) => {
    if (option.isRegion) {
      const pattern = config.urlPatterns && config.urlPatterns.region;
      if (pattern && option.value) {
        document.location.href = pattern.replace('{regionIso3}', option.value);
      }
    } else {
      const pattern = config.urlPatterns && config.urlPatterns.country;
      if (pattern && option.value) {
        document.location.href = pattern.replace('{countryIso3}', option.value);
      }
    }
  }, [config]);

  /**
   * Rebuild tooltips
   * (When activeDimension changes from null to value, a new tooltip
   * trigger icon gets rendered and needs to be initialized.)
   */

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activeDimension]);

  /**
   * Update hash
   */

  useEffect(() => {
    const selectedCategoriesBits = [1, 2, 3, 4, 5].map(
      cat => (selectedCategories.includes(`${cat}`) ? '1' : '0'),
    ).join('');

    storeQueryData(HASH_MAP, { activeDimension, selectedCategories, selectedCategoriesBits });
  }, [activeDimension, selectedCategories]);

  /**
   * Compute data
   */

  const countryAndRegionOptions = useCountryAndRegionOptions(data.countries, data.regions, region);
  const dimensionTitles = useDimensionTitles(data.dimensions, data.index);
  const activeDimensionTitle = useActiveDimensionTitle(dimensionTitles, activeDimension);

  /**
   * Render
   */

  if (!config || Object.keys(data).length < dataKeys.length) {
    return (
      <div className="bti-map__loading">
        {t('Lade ...')}
      </div>
    );
  }

  const mapImageData = data.indexMap.YEAR[Object.keys(data.indexMap.YEAR)[0]][activeDimension];
  const regionName = region && data.regions[region].name;
  const dimensions = [DIMENSION.D, DIMENSION.E, DIMENSION.G];
  const globalUrl = config.urls && config.urls.global;
  const atlasUrl = data.regionDashboard
    ? data.regionDashboard.META.ATLAS1
    : config.urls.atlas;
  const atlasButtonText = data.regionDashboard
    ? t('Mehr über regionale Transformationsprozesse im BTI-Atlas')
    : t('Mehr über globale Transformationsprozesse im BTI-Atlas');
  const sentenceText = data.regionDashboard
    ? data.regionDashboard.BODY['In One Sentence']
    : data.indexMap.BODY['In One Sentence'];

  return (
    <>
      <div className="bti-map__dashboard">

        <div className="bti-map__dashboard-header">
          <div className="bti-map__dashboard-header__headline">
            <div className="bti-map__dashboard-header__headline__text">
              {region ? regionName : t('Globale Ergebnisse')}
            </div>
            {region && globalUrl && (
              <div className="bti-map__dashboard-header__headline__back">
                <a className="bti-map__dashboard-header__headline__back__link" href={globalUrl}>
                  <Icon icon="hook-left" className="bti-country-dashboard__header__headline__back__link__icon" />
                  {t('Zu globalen Ergebnissen')}
                </a>
              </div>
            )}
          </div>

          <div className="bti-map__dashboard-header__select-dashboard">
            <BTIDashboardSelect
              placeholder={region ? t('Länder der Region durchsuchen') : t('Länder und Regionen durchsuchen')}
              options={countryAndRegionOptions}
              onChange={handleCountryAndRegionSelectChange}
              variant='medium-opaque'
            />
          </div>
        </div>

        <div className="bti-map__select-dimension">
          <div className="bti-map__select-dimension__headline">
            {t('Wählen Sie eine Dimension')}
            <BTIDashboardInfoIcon tooltipId="select-dimension-tooltip" event="click" />
          </div>
          <DimensionSelectButtonList
            dimensionTitles={dimensionTitles}
            dimensions={dimensions}
            activeDimension={activeDimension}
            callback={setActiveDimension}
            className="bti-map__select-dimension__buttons"
          />
        </div>

        <div className="bti-map__dashboard-scroller-and-aside-wrap">

          <div className="bti-map__dashboard-scroller-and-aside-wrap__scroller">
            <MapScroller className="bti-map__dashboard-scroller">
              <MapImage
                activeDimension={activeDimension}
                colors={config.colors.mapColors[activeDimension]}
                countries={data.countries}
                countryUrlPattern={config.urlPatterns && config.urlPatterns.country}
                data={mapImageData}
                region={data.regions[region]}
                selectedCategories={selectedCategories}
              />
            </MapScroller>
          </div>

          <div className="bti-map__dashboard-share-button-container bti-map__dashboard-share-button-container--small">
            <BTIDashboardShareButton />
          </div>

          <div className="bti-map__dashboard-scroller-and-aside-wrap__aside">

            {activeDimension && (
              <div className="bti-map__select-category bti-map__select-category--dashboard">
                <div className="bti-map__select-category__headline">
                  {activeDimensionTitle}
                  <BTIDashboardInfoIcon tooltipId="select-category-tooltip" />
                </div>
                <CategorySelectButtonList
                  activeDimension={activeDimension}
                  callback={item => dispatchSelectedCategories({ type: 'toggle', item })}
                  categories={data.categories}
                  className="bti-map__select-category__buttons"
                  colors={config.colors}
                  selectedCategories={selectedCategories}
                />
              </div>
            )}

            {!activeDimension && sentenceText && (
              <BTIDashboardSentence
                headline={t('In einem Satz')}
                text={sentenceText}
                twitterLinktext={t('Diskutieren Sie mit uns auf Twitter!')}
              />
            )}

            {atlasUrl && (
              <div className="bti-map__dashboard-atlas-button-container">
                <Button
                  link={atlasUrl}
                  style="secondary"
                  icon="arrow-up-right"
                >
                  {atlasButtonText}
                </Button>
              </div>
            )}

          </div>

        </div>

        <div className="bti-map__dashboard-share-button-container bti-map__dashboard-share-button-container--large">
          <BTIDashboardShareButton reversed />
        </div>

      </div>

      <SelectDimensionTooltip
        id="select-dimension-tooltip"
        text={data.text.map.selectDimensionTooltip}
      />

      <SelectCategoryTooltip
        id="select-category-tooltip"
        event="click"
        headline={activeDimensionTitle}
        text={data.text.map.selectCategoryTooltip[activeDimension]}
        url={data.text.url.more}
        legendItems={
          activeDimension && data.categories[activeDimension]
          && Object.keys(data.categories[activeDimension]).map((key) => {
            const value = data.categories[activeDimension][key];
            return {
              key,
              name: value.nam,
              max: value.max,
              min: value.min,
              color: config.colors.mapColors[activeDimension][key],
            };
          })}
      />

    </>
  );
};

export default BTIDashboardMap;

import React from 'react';
import DimensionSelectButton from '../DimensionSelectButton';

const DimensionSelectButtonList = ({
  activeDimension,
  callback,
  className,
  dimensions,
  dimensionTitles,
}) => (
  <ul className={className}>
    {dimensions.map(dimension => (
      <li key={dimension}>
        <DimensionSelectButton
          title={dimensionTitles[dimension]}
          active={activeDimension === dimension}
          inactive={activeDimension && activeDimension !== dimension}
          onClick={() => callback(dimension)}
        />
      </li>
    ))}
  </ul>
);

export default DimensionSelectButtonList;

import React from 'react';
import CategorySelectButton from '../CategorySelectButton';

const CategorySelectButtonList = ({
  activeDimension,
  callback,
  categories,
  className,
  colors,
  selectedCategories,
}) => (
  <ul className={className}>
    {activeDimension && categories[activeDimension]
    && Object.keys(categories[activeDimension]).map(key => (
      <li key={key}>
        <CategorySelectButton
          color={colors.mapColors[activeDimension][key]}
          title={categories[activeDimension][key].nam}
          onClick={() => callback(key)}
          active={selectedCategories.includes(key)}
        />
      </li>
    ))}
  </ul>
);

export default CategorySelectButtonList;

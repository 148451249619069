import React from 'react';
import ReactDOM from 'react-dom';

import BTIIndexMap from './BTIIndexMap';
import BTIDashboardMap from './BTIDashboardMap';

const mapComponents = {
  index: BTIIndexMap,
  dashboard: BTIDashboardMap,
};

export const initBTIMap = (container) => {
  container.classList.add('bti-map--initialized');

  const variant = container.getAttribute('data-bti-map-variant');
  const configUrl = container.getAttribute('data-bti-map-config-url');
  let region = container.getAttribute('data-bti-map-region');

  const MapComponent = mapComponents[variant];

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('region')) {
    region = urlParams.get('region');
    window.console.info('Using region passed by url param', region);
  }

  ReactDOM.render((
    <MapComponent
      configUrl={configUrl}
      region={region}
    />
  ), container);
};

export const initBTIMaps = () => {
  document.querySelectorAll('.bti-map:not(.bti-map--initialized)').forEach(initBTIMap);
};

initBTIMaps();

// @flow

// Feature test for .toLocaleString
// Excludes <= IE10 and browsers not fully supporting the Internationalization API.
// See: https://caniuse.com/#search=intl
const supportsToLocaleString = (() => (
  // Type unsafe check is intended
  // eslint-disable-next-line eqeqeq
  !!(typeof window.Intl == 'object' && window.Intl && typeof window.Intl.NumberFormat == 'function')
))();

export default function formatNumber(number: number, fractionDigits?: number = 0): string {
  if (!supportsToLocaleString) {
    return String(number);
  }

  const lang = document.querySelector('html').getAttribute('lang') || 'de-DE';

  return number.toLocaleString(
    lang,
    { minimumFractionDigits: fractionDigits },
  );
}

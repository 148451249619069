// @flow
import * as React from 'react';
import MediaQuery from 'react-responsive';
import Dialog from '../dialog/Dialog';
import Button from '../../atoms/button/Button';
import ImageSlider from './ImageSlider';
import ThumbnailTrack from './ThumbnailTrack';
import { t } from '../../../javascripts/utils/withTranslation';

import { MEDIA_QUERIES } from '../../../javascripts/constants';

export type ImageT = {
  src: string,
  link: string,
  alt?: string,
  caption?: string,
  source?: string,
  index: number,
  width: number,
  height: number,
};

type State = {
  currentImage: number,
};

type Props = {
  isLightboxOpen: boolean,
  images: Array<ImageT>,
  currentImage: number,
  onClose: Function,
};

export default class Lightbox extends React.Component<Props, State> {
  dialog: ?Dialog;

  state = {
    currentImage: this.props.currentImage,
  };

  handleOnNextImageClick = () => {
    const { currentImage } = this.state;
    const maxIndex = this.props.images.length - 1;
    const newCurrentImage = currentImage < maxIndex ? currentImage + 1 : 0;

    this.setState({
      currentImage: newCurrentImage,
    });
  };

  handleOnPrevImageClick = () => {
    const { currentImage } = this.state;
    const maxIndex = this.props.images.length - 1;
    const newCurrentImage = currentImage > 0 ? currentImage - 1 : maxIndex;

    this.setState({
      currentImage: newCurrentImage,
    });
  };

  handleSetCurrentImage = (currentImage: number) => {
    this.setState({ currentImage });
  };

  renderCloseButton = () => <Button
    onClick={this.props.onClose}
    icon="close"
    outline={true}
    small={true}
    className="lightbox__close-button"
    title={t('Schließen')}
  />;

  render() {
    const { images, onClose } = this.props;
    const { currentImage } = this.state;
    const dialogParent = document.body;

    if (dialogParent == null) return null;

    return (
      <Dialog
        ref={(node) => {
          this.dialog = node;
        }}
        dialogParent={dialogParent}
        onClose={onClose}
        className="lightbox"
        dialogScrollInnerClassName='lightbox__inner'
      >
        {this.renderCloseButton()}

        <div className="lightbox__content">
          <ImageSlider
            onNextImageClick={this.handleOnNextImageClick}
            onPrevImageClick={this.handleOnPrevImageClick}
            currentImage={currentImage}
            images={images}
          />

          {images.length > 1 && (
            <MediaQuery query={MEDIA_QUERIES.l}>
              {matches => (matches ? (
                <ThumbnailTrack
                  currentImage={currentImage}
                  images={images}
                  setCurrentImage={this.handleSetCurrentImage}
                  numberOfThumbnails={7}
                />
              ) : null)}
            </MediaQuery>
          )}
        </div>
      </Dialog>
    );
  }
}

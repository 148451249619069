import React, { useState } from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import Icon from '../../_particles/icon/Icon';
import { t } from '../../../javascripts/utils/withTranslation';

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon icon="hook-down" className="bti-dashboard-select__dropdown-indicator__icon" />
  </components.DropdownIndicator>
);

const BTIDashboardSelect = ({
  onChange,
  options,
  placeholder,
  variant,
  menuIsOpen,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <Select
      components={{
        DropdownIndicator: CustomDropdownIndicator,
      }}
      className={classNames([
        'bti-dashboard-select',
        `bti-dashboard-select--${variant || 'default'}`,
      ])}
      classNamePrefix='bti-dashboard-select'
      onChange={(option) => {
        setSelectedOption(option);
        if (onChange) {
          onChange(option);
        }
      }}
      options={options}
      noOptionsMessage={() => (<span>{t('Keine Ergebnisse')}</span>)}
      value={selectedOption}
      placeholder={placeholder}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default BTIDashboardSelect;

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import Icon from '../../_particles/icon/Icon';
import { t } from '../../../javascripts/utils/withTranslation';

import { useCountryOptions } from '../../../javascripts/bti/shared/hooks';
import { loadConfigAndData } from '../../../javascripts/bti/shared/loadConfigAndData';

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon icon="search" className="bti-country-select__dropdown-indicator__icon" />
  </components.DropdownIndicator>
);

const BTICountrySelect = ({
  configUrl,
  menuIsOpen,
  placeholder,
  variant,
}) => {
  const [data, setData] = useState({});
  const [config, setConfig] = useState({});

  /**
   * Load config and data
   */

  useEffect(() => {
    loadConfigAndData({
      keys: ['countries'],
      configUrl,
      setConfig,
      setData,
    });
  }, []); // run once

  /**
   * Compute data
   */

  const countryOptions = useCountryOptions(data.countries);

  /**
   * Render
   */

  if (!countryOptions || !countryOptions.length) {
    return null;
  }

  return (
    <Select
      components={{
        DropdownIndicator: CustomDropdownIndicator,
      }}
      className={classNames([
        'bti-country-select',
        `bti-country-select--${variant || 'default'}`,
      ])}
      classNamePrefix='bti-country-select'
      onChange={(option) => {
        document.location.href = config.urlPatterns.country.replace('{countryIso3}', option.value);
      }}
      options={countryOptions}
      noOptionsMessage={() => (<span>{t('Keine Ergebnisse')}</span>)}
      placeholder={placeholder || t('Land auswählen')}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default BTICountrySelect;

import lazySizes from 'lazysizes';

const printWithLazyImages = () => {
  const sleep = (waitSec, callbackFunc) => {
    let spanedSec = 0;
    let id = null;
    const waitFunc = () => {
      spanedSec += 1;
      if (spanedSec >= waitSec) {
        if (callbackFunc) callbackFunc();
        return;
      }

      clearTimeout(id);
      id = setTimeout(waitFunc, 1000);
    };
    id = setTimeout(waitFunc, 1000);
  };

  const unveilAndPrint = () => {
    let ctr = 0;
    document.querySelectorAll('img.js-lazyload').forEach((v, k, array) => {
      lazySizes.loader.unveil(v);
      ctr += 1;
      if (ctr === array.length) {
        sleep(1, () => {
          window.print();
          ctr = 0;
        });
      }
    });
  };

  unveilAndPrint();
};

document.querySelectorAll('.js-print').forEach(($target) => {
  $target.addEventListener('click', (event) => {
    // Prevent default event
    event.preventDefault();

    // Print site containing lazyloading images
    printWithLazyImages();
  });
});

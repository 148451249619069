// @flow
import * as React from 'react';
import format from 'string-template';
import IconLink from '../../atoms/icon-link/IconLink';
import { t } from '../../../javascripts/utils/withTranslation';
import { TWITTER_SHARE_ACCOUNT } from '../../../javascripts/constants';

type SocialSharingOptions = {
  url: string,
  title: string,
  twitterAccount: string,
};

const allServices = {
  facebook: {
    name: 'Facebook',
    icon: 'facebook',
    link: 'https://www.facebook.com/sharer/sharer.php?u={url}',
    popup: true,
  },
  twitter: {
    name: 'Twitter',
    icon: 'twitter',
    link: 'http://twitter.com/intent/tweet?text={title}&url={url}&via={twitterAccount}',
    popup: true,
  },
  googleplus: {
    name: 'Google+',
    icon: 'googleplus',
    link: 'https://plus.google.com/share?url={url}',
    popup: true,
  },
  linkedin: {
    name: 'LinkedIn',
    icon: 'linkedin',
    link: 'https://www.linkedin.com/shareArticle?mini=true&url={url}',
    popup: true,
  },
  reddit: {
    name: 'Reddit',
    icon: 'reddit',
    link: 'http://www.reddit.com/submit?url={url}&title={title}',
    popup: true,
  },
  pinterest: {
    name: 'Pinterest',
    icon: 'pinterest',
    link: 'https://pinterest.com/pin/create/button/?url={url}',
    popup: true,
  },
  whatsapp: {
    name: 'WhatsApp',
    icon: 'whatsapp',
    link: 'https://wa.me/?text={title}+{url}',
    popup: true,
  },
  xing: {
    name: 'XING',
    icon: 'xing',
    link: 'https://www.xing.com/spi/shares/new?url={url}',
    popup: true,
  },
  mail: {
    name: 'Mail',
    icon: 'mail-solid',
    link: 'mailto:?subject={title}&body={url}',
    popup: false,
  },
};

export const getSharingLink = ({
  service,
  url = window.location,
  title = document.title,
  twitterAccount = TWITTER_SHARE_ACCOUNT,
}) => {
  const { link } = allServices[service];
  return format(link, {
    url: encodeURIComponent(url),
    title: encodeURIComponent(title),
    twitterAccount: encodeURIComponent(twitterAccount),
  });
};

const renderService = ({
  name,
  icon,
  link,
  popup,
}, index) => (
  <li key={index} className="social-sharing__item">
    <IconLink
      className={popup && 'js-popup'}
      link={link}
      stacked
      icon={icon}
      attr={{
        'aria-label': t('Auf {name} teilen.', { name }),
        'data-name': name,
      }}
    >
      {name}
    </IconLink>
  </li>
);

const SocialSharing = ({
  services,
  url = window.location,
  title = document.title,
  twitterAccount = TWITTER_SHARE_ACCOUNT,
}: SocialSharingOptions) => {
  let showServices = [];
  if (Array.isArray(services) && services.length) {
    showServices = services.filter(key => allServices[key]).map(key => allServices[key]);
  } else {
    showServices = allServices;
  }

  return (
    <div className="social-sharing">
      <ul className="social-sharing__list">
        {Object.values(showServices)
          .map(({ link, ...service }) => ({
            ...service,
            link: format(link, {
              url: encodeURIComponent(url),
              title: encodeURIComponent(title),
              twitterAccount: encodeURIComponent(twitterAccount),
            }),
          }))
          .map(renderService)}
      </ul>
    </div>
  );
};

export default SocialSharing;

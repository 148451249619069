import { loadData } from '../helpers/loadData';

const SOURCE_KEYS = {
  // Static
  categories: 'Categories',
  countries: 'Countries',
  criteria: 'Criteria',
  dimensions: 'Dimensions',
  index: 'Index',
  indicators: 'Indicators',
  regions: 'Regions',
  // CMS
  rankingDownloads: 'RankingDownloads',
  text: 'Text',
};

const getHTMLLanguageCode = ({ defaultCode }) => {
  const lang = document.querySelector('html').getAttribute('lang') || 'en';
  let code = lang.split('-')[0].toUpperCase;
  if (!['DE', 'EN'].includes(code)) {
    code = defaultCode || 'EN';
  }
  return code;
};

export const loadConfigAndData = ({
  configUrl,
  keys,
  urlData,
  // callbacks
  setConfig,
  setData,
}) => {
  window.console.log('Load config and data', { configUrl, keys, urlData });
  loadData(configUrl).then((config) => {
    if (setConfig) {
      setConfig(config);
    }
    const finalUrlData = urlData || {};
    finalUrlData.year = finalUrlData.year || config.defaultYear;
    finalUrlData.lang = finalUrlData.lang
      || getHTMLLanguageCode({ defaultCode: config.defaultLang });

    if (config.API) {
      const dataSets = keys.map((key) => {
        let url = config.API[key];
        Object.keys(finalUrlData).forEach((finalUrlDataKey) => {
          url = url.replace(`{${finalUrlDataKey}}`, finalUrlData[finalUrlDataKey]);
        });
        const sourceKey = SOURCE_KEYS[key];
        return { key, sourceKey, url };
      });
      loadData(dataSets).then((_data) => {
        if (setData) {
          setData(_data);
        }
      });
    }
  });
};

import React, {
  useRef,
  useEffect,
  useState,
} from 'react';

import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import CountryTooltip from '../CountryTooltip';

import World from './world.svg';
import RegionASO from './region-ASO.svg';
import RegionCIS from './region-CIS.svg';
import RegionENA from './region-ENA.svg';
import RegionESA from './region-ESA.svg';
import RegionESE from './region-ESE.svg';
import RegionLAC from './region-LAC.svg';
import RegionWCA from './region-WCA.svg';

const SVG_COUNTRY_PREFIX = 'country-';

const getSVG = (region) => {
  if (!region) {
    return World;
  }
  return {
    ASO: RegionASO,
    CIS: RegionCIS,
    ENA: RegionENA,
    ESA: RegionESA,
    ESE: RegionESE,
    LAC: RegionLAC,
    WCA: RegionWCA,
  }[region];
};

const getSVGClassPrefix = (region) => {
  if (region) {
    return `region-${region}_svg__`;
  }
  return 'world_svg__';
};

const getIso3FromPathClassList = ({ classList, removePrefix }) => {
  let iso3;
  classList.forEach((fullClassName) => {
    const className = fullClassName.replace(removePrefix, '');
    if (className.length === SVG_COUNTRY_PREFIX.length + 3) {
      const candidate = className.replace(SVG_COUNTRY_PREFIX, '');
      if (candidate.length === 3) {
        iso3 = candidate;
      }
    }
  });
  return iso3;
};

const getCountryInfo = ({
  iso3, // required
  colors,
  countries,
  data,
  urlPattern,
}) => {
  if (!iso3) {
    return {};
  }
  const name = countries && countries[iso3] && countries[iso3].name;
  const cat = data && data.CAT && `${data.CAT[iso3]}`;
  const rnk = data && data.RNK && `${data.RNK[iso3]}`;
  const val = data && data.VAL && `${data.VAL[iso3]}`;
  const color = colors && cat && colors[cat];
  const url = urlPattern && urlPattern.replace('{countryIso3}', iso3);
  return {
    iso3,
    cat,
    color,
    name,
    rnk,
    url,
    val,
  };
};

const getPathInfo = (props) => {
  const iso3 = props.path.getAttribute('data-country-iso3');
  return getCountryInfo({ ...props, iso3 });
};

const MapImage = ({
  activeDimension,
  colors,
  countries,
  countryUrlPattern,
  data,
  selectedCategories,
  region,
  responsive,
}) => {
  const me = useRef(null);
  const [hoverCountry, setHoverCountry] = useState(null);
  const [hoverCountryInfo, setHoverCountryInfo] = useState(null);

  /**
   * Hover & click handlers
   */

  const updateHoverCountry = (event) => {
    const iso3 = event.target.getAttribute('data-country-iso3');
    setHoverCountry(iso3);
  };

  useEffect(() => {
    const info = getCountryInfo({ iso3: hoverCountry, data, countries });
    setHoverCountryInfo(info);
  }, [hoverCountry, data]);

  const onCountryClick = (event) => {
    const iso3 = event.target.getAttribute('data-country-iso3');
    const { url } = getCountryInfo({ iso3, urlPattern: countryUrlPattern });
    if (url) {
      document.location.href = url;
    }
  };

  /**
   * Prepare paths, attatch event listeners
   */

  useEffect(() => {
    const mappedCountries = [];
    me.current.querySelectorAll('svg path').forEach((path) => {
      const iso3 = getIso3FromPathClassList({
        classList: path.classList,
        removePrefix: getSVGClassPrefix(region && region.ISO3),
      });

      if (iso3) {
        // For internal reference:
        path.setAttribute('data-country-iso3', iso3);
        mappedCountries.push(iso3);

        if (region ? region.countries.includes(iso3) : !!countries[iso3]) {
          // For CSS styling:
          path.classList.add('country');

          // For ReactTooltip:
          path.setAttribute('data-tip', iso3);
          path.setAttribute('data-for', 'country-tooltip');

          // Event listeners
          path.addEventListener('mouseenter', updateHoverCountry);
          path.addEventListener('mousedown', updateHoverCountry);
          path.addEventListener('touchdown', updateHoverCountry);
          path.addEventListener('mouseup', onCountryClick);
          path.addEventListener('touchup', onCountryClick);
        } else {
          path.classList.add('untracked-country');
          window.console.info('Map contains untracked country', iso3);
        }
      }
    });

    ReactTooltip.rebuild();

    if (region && region.countries) {
      region.countries.forEach((countryIso3) => {
        if (!mappedCountries.includes(countryIso3)) {
          window.console.warn('Map should contain region country', countryIso3);
        }
      });
    }
  }, [region]);

  /**
   * Colorize
   */

  useEffect(() => {
    me.current.querySelectorAll('svg path').forEach((path) => {
      const { color, cat } = getPathInfo({ path, data, colors });
      if (color) {
        path.setAttribute('fill', color);
      }
      if (!activeDimension && selectedCategories.length === 0) {
        path.classList.remove('selected');
        path.classList.remove('unselected');
      } else if (selectedCategories.length === 0 || selectedCategories.includes(cat)) {
        path.classList.add('selected');
        path.classList.remove('unselected');
      } else {
        path.classList.add('unselected');
        path.classList.remove('selected');
      }
    });
  }, [data, selectedCategories, activeDimension]);

  /**
   * Render
   */

  const MapSVG = getSVG(region && region.ISO3);

  return (
    <div ref={me} className={classNames({
      'bti-map-map-image': true,
      'bti-map-map-image--responsive': responsive,
    })}>
      <MapSVG />
      <CountryTooltip id="country-tooltip" {...hoverCountryInfo} />
    </div>
  );
};

export default MapImage;

import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from 'react';

import ReactTooltip from 'react-tooltip';

import { t } from '../../../javascripts/utils/withTranslation';
import { loadQueryData, storeQueryData } from '../../../javascripts/utils/query-data';

import BTIDashboardInfoIcon from '../../molecules/bti-dashboard-info-icon/BTIDashboardInfoIcon';
import BTIDashboardSelect from '../../molecules/bti-dashboard-select/BTIDashboardSelect';
import BTIDashboardShareButton from '../../molecules/bti-dashboard-share-button/BTIDashboardShareButton';

import CategorySelectButtonList from './components/CategorySelectButtonList';
import CountriesInfoTooltip from './components/CountriesInfoTooltip';
import DimensionSelectButtonList from './components/DimensionSelectButtonList';
import GoButton from './components/GoButton';
import MapImage from './components/MapImage';
import MapScroller from './components/MapScroller';
import SelectCategoryTooltip from './components/SelectCategoryTooltip';
import SelectDimensionTooltip from './components/SelectDimensionTooltip';

import { listReducer } from '../../../javascripts/bti/helpers/listReducer';

import { DIMENSION } from '../../../javascripts/bti/shared/constants';

import {
  useConfigAndData,
  useActiveDimensionTitle,
  useCountryOptions,
  useDimensionTitles,
  useRegionOptions,
} from '../../../javascripts/bti/shared/hooks';


const HASH_MAP = {
  activeDimension: {
    short: 'd',
    type: 'string',
  },
  selectedCategories: {
    short: 'c',
    type: 'array',
  },
  selectedCategoriesBits: {
    short: 'cb',
    type: 'string',
  },
};


const BTIIndexMap = ({ configUrl }) => {
  const initialHashData = useMemo(() => loadQueryData(HASH_MAP), []);
  const [activeDimension, _setActiveDimension] = useState(initialHashData.activeDimension);
  const [selectedCategories, dispatchSelectedCategories] = useReducer(
    listReducer,
    initialHashData.selectedCategories,
  );

  const setActiveDimension = (dimension) => {
    _setActiveDimension(dimension !== activeDimension ? dimension : null);
    dispatchSelectedCategories({ type: 'reset' });
  };

  /**
   * Load config and data
   */

  const dataKeys = ['indexMap', 'categories', 'countries', 'dimensions', 'index', 'regions', 'text'];
  const [config, data] = useConfigAndData({
    keys: dataKeys,
    configUrl,
  });

  /**
   * Event handlers
   */

  const handleCountrySelectChange = useCallback((option) => {
    const pattern = config.urlPatterns && config.urlPatterns.country;
    if (pattern && option.value) {
      document.location.href = pattern.replace('{countryIso3}', option.value);
    }
  }, [config]);

  const handleRegionSelectChange = useCallback((option) => {
    const pattern = config.urlPatterns && config.urlPatterns.region;
    if (pattern && option.value) {
      document.location.href = pattern.replace('{regionIso3}', option.value);
    }
  }, [config]);

  /**
   * Rebuild tooltips
   * (When activeDimension changes from null to value, a new tooltip
   * trigger icon gets rendered and needs to be initialized.)
   */

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activeDimension]);

  /**
   * Update hash
   */

  useEffect(() => {
    const selectedCategoriesBits = [1, 2, 3, 4, 5].map(
      cat => (selectedCategories.includes(`${cat}`) ? '1' : '0'),
    ).join('');

    storeQueryData(HASH_MAP, { activeDimension, selectedCategories, selectedCategoriesBits });
  }, [activeDimension, selectedCategories]);

  /**
   * Compute data
   */

  const countryOptions = useCountryOptions(data.countries);
  const regionOptions = useRegionOptions(data.regions);
  const dimensionTitles = useDimensionTitles(data.dimensions, data.index);
  const activeDimensionTitle = useActiveDimensionTitle(dimensionTitles, activeDimension);

  /**
   * Render
   */

  if (!config || Object.keys(data).length < dataKeys.length) {
    return (
      <div className="bti-map__loading">
        {t('Lade ...')}
      </div>
    );
  }
  const mapImageData = data.indexMap.YEAR[Object.keys(data.indexMap.YEAR)[0]][activeDimension];
  const dimensions = [DIMENSION.D, DIMENSION.E, DIMENSION.G];
  const globalUrl = config.urls && config.urls.global;

  return (
    <>
      <div className="bti-map__fallback">

        <div className="bti-map__fallback__headline">
          {t('BTI entdecken')}
        </div>
        <ul className="bti-map__fallback__select-map">
        <li>
            <BTIDashboardSelect
              variant="big"
              placeholder={t('Länder durchsuchen')}
              options={countryOptions}
              onChange={handleCountrySelectChange}
            />
          </li>
          <li>
            <BTIDashboardSelect
              variant="big"
              placeholder={t('Regionen durchsuchen')}
              options={regionOptions}
              onChange={handleRegionSelectChange}
            />
          </li>
          <li>
            <GoButton
              variant="big"
              title={t('Überblick')}
            />
          </li>
        </ul>
        <div className="bti-map__fallback__countries-info">
          {t('Welche Länder sind im BTI zu finden?')}
          <BTIDashboardInfoIcon tooltipId="countries-info-tooltip" event="click" />
        </div>

      </div>
      <div className="bti-map__regular">

        <div className="bti-map__countries-info">
          {t('Welche Länder sind im BTI zu finden?')}
          <BTIDashboardInfoIcon tooltipId="countries-info-tooltip" event="click" />
        </div>

        <div className="bti-map__select-dimension">
          <div className="bti-map__select-dimension__headline">
            {t('Wählen Sie eine Dimension')}
            <BTIDashboardInfoIcon tooltipId="select-dimension-tooltip" event="click" />
          </div>
          <DimensionSelectButtonList
            dimensionTitles={dimensionTitles}
            dimensions={dimensions}
            activeDimension={activeDimension}
            callback={setActiveDimension}
            className="bti-map__select-dimension__buttons"
          />
        </div>

        <div className="bti-map__scroller-and-select-wrap">

          <div className="bti-map__map-scroller-container">
            <MapScroller
              className="bti-map__map-scroller"
              fullWidth
            >
              <MapImage
                activeDimension={activeDimension}
                colors={config.colors.mapColors[activeDimension]}
                countries={data.countries}
                countryUrlPattern={config.urlPatterns && config.urlPatterns.country}
                data={mapImageData}
                selectedCategories={selectedCategories}
              />
            </MapScroller>
          </div>

          {activeDimension && (
            <div className="bti-map__select-category bti-map__select-category--index">
              <div className="bti-map__select-category__headline">
                {activeDimensionTitle}
                <BTIDashboardInfoIcon tooltipId="select-category-tooltip" />
              </div>
              <CategorySelectButtonList
                activeDimension={activeDimension}
                callback={item => dispatchSelectedCategories({ type: 'toggle', item })}
                categories={data.categories}
                className="bti-map__select-category__buttons"
                colors={config.colors}
                selectedCategories={selectedCategories}
              />
            </div>
          )}

          <div className="bti-map__share-button-container bti-map__share-button-container--small">
            <BTIDashboardShareButton reversed />
          </div>

        </div>

        <ul className="bti-map__select-map">
          {globalUrl && (
            <li>
              <GoButton
                title={t('Overview')}
                href={globalUrl}
              />
            </li>
          )}
          <li>
            <BTIDashboardSelect
              placeholder={t('Regionen durchsuchen')}
              options={regionOptions}
              onChange={handleRegionSelectChange}
            />
          </li>
          <li>
            <BTIDashboardSelect
              placeholder={t('Länder durchsuchen')}
              options={countryOptions}
              onChange={handleCountrySelectChange}
            />
          </li>
        </ul>

        <div className="bti-map__share-button-container bti-map__share-button-container--large">
          <BTIDashboardShareButton reversed />
        </div>

      </div>

      <CountriesInfoTooltip
        id="countries-info-tooltip"
        text={data.text.map.countriesInfoTooltip}
        place="bottom"
      />

      <SelectDimensionTooltip
        id="select-dimension-tooltip"
        text={data.text.map.selectDimensionTooltip}
      />

      <SelectCategoryTooltip
        id="select-category-tooltip"
        event="click"
        headline={activeDimensionTitle}
        text={data.text.map.selectCategoryTooltip[activeDimension]}
        url={data.text.url.more}
        legendItems={
          activeDimension && data.categories[activeDimension]
          && Object.keys(data.categories[activeDimension]).map((key) => {
            const value = data.categories[activeDimension][key];
            return {
              key,
              name: value.nam,
              max: value.max,
              min: value.min,
              color: config.colors.mapColors[activeDimension][key],
            };
          })}
      />

    </>
  );
};

export default BTIIndexMap;

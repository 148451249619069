import React from 'react';
import { VictoryPie } from 'victory';
// import lerpColor from '@sunify/lerp-color';
import classNames from 'classnames';

import DashedCircle from './dashed-circle.svg';
import DashedCircleSmall from './dashed-circle-small.svg';
import formatNumber from '../../../../../javascripts/utils/format-number';


const prefixedClass = (prefix, name) => {
  if (prefix) {
    return `bti-country-dashboard__dimension-donut${name} ${prefix}${name}`;
  }
  return `bti-country-dashboard__dimension-donut${name}`;
};

const DimensionDonut = ({
  color,
  colorRange,
  className,
  classPrefix,
  displayValue,
  label,
  value,
  valueDisplay,
  renderSize,
  lineWidth,
}) => {
  let colorToUse = '#000';
  if (color) {
    colorToUse = color;
  }
  if (colorRange) {
    // colorToUse = lerpColor(colorRange, value / 10);
    colorToUse = colorRange[Math.round((value / 10) * colorRange.length) - 1];
  }

  let valueToDisplay = null;
  if (displayValue) {
    if (valueDisplay) {
      valueToDisplay = valueDisplay;
    } else {
      valueToDisplay = formatNumber(parseFloat(value), 2);
    }
  }

  const lineWidthToUse = lineWidth || 8;
  const size = renderSize || 200;
  const radius = size / 2;
  const innerRadius = radius - lineWidthToUse;
  const cornerRadius = lineWidthToUse;

  const dashedCirclePSize = ((size - lineWidthToUse + 2) / size) * 100;
  const dashedCirclePPos = ((lineWidthToUse - 2) / size / 2) * 100;
  const dashedCircleStyle = {
    height: `${dashedCirclePSize}%`,
    width: `${dashedCirclePSize}%`,
    left: `${dashedCirclePPos}%`,
    top: `${dashedCirclePPos}%`,
  };

  return (
    <div className={classNames('bti-country-dashboard__dimension-donut', className)}>
      <div className="bti-country-dashboard__dimension-donut__image">
        <div
          className="bti-country-dashboard__dimension-donut__image__dashed-circle"
          style={dashedCircleStyle}
        >
          {size <= 100 && <DashedCircleSmall />}
          {size > 100 && <DashedCircle />}
        </div>
        <div className="bti-country-dashboard__dimension-donut__image__pie">
          <VictoryPie
            width={size} height={size}
            radius={radius}
            innerRadius={innerRadius}
            cornerRadius={cornerRadius}
            style={{ labels: { display: 'none' } }}
            colorScale={[colorToUse, 'transparent']}
            data={[
              { x: 1, y: parseFloat(value) },
              { x: 2, y: 10 - parseFloat(value) },
            ]}
          />
        </div>
      </div>
      {(valueToDisplay || label) && (
        <div className={prefixedClass(classPrefix, '__inner')}>
          {valueToDisplay && (
            <div className={prefixedClass(classPrefix, '__inner__value')}>
              {valueToDisplay}
            </div>
          )}
          {label && (
            <div className={prefixedClass(classPrefix, '__inner__label')}>
              {label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DimensionDonut;

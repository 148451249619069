const submitFormAndUpdateContent = (form, selector) => {
  const data = new FormData(form);
  const request = new XMLHttpRequest();
  request.open('POST', form.action);

  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      const old = document.querySelector(selector);

      if (request.status === 200) {
        const parser = new DOMParser();
        const html = parser.parseFromString(request.responseText, 'text/html');
        const content = html.querySelector(selector);
        if (content && old) {
          old.parentNode.replaceChild(content, old);
        }
      } else {
        old.classList.remove('is-loading');
      }

      form.classList.remove('is-sending');
    }
  };

  request.send(data);
};

export const initContentUpdateForm = (form) => {
  form.addEventListener('submit', (event) => {
    event.preventDefault();
    if (!form.classList.contains('is-sending')) {
      form.classList.add('is-sending');
      const selector = form.getAttribute('data-form-updates-content');
      document.querySelector(selector).classList.add('is-loading');
      submitFormAndUpdateContent(form, selector);
    }
    return false;
  });

  form.querySelectorAll('input').forEach((input) => {
    input.addEventListener('change', () => {
      input.blur();
      form.dispatchEvent(new Event('submit'));
    });
  });
};

document.querySelectorAll('form[data-form-updates-content]').forEach(initContentUpdateForm);

import React from 'react';
import ReactDOM from 'react-dom';
import BTIReportDashboard from './BTIReportDashboard';

document.querySelectorAll('.report-dashboard').forEach((container) => {
  const configUrl = container.getAttribute('data-bti-report-dashboard-config-url');
  const country = container.getAttribute('data-bti-report-dashboard-country');
  ReactDOM.render((
    <BTIReportDashboard
      configUrl={configUrl}
      country={country}
    />
  ), container);
});

import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from '../../_particles/icon/Icon';

const Collapsible = ({
  children,
  expanded,
  title,
}) => {
  const [collapsed, setCollapsed] = useState(!expanded);

  return (
    <div
      className={classNames('collapsible collapsible--react', {
        'collapsible--collapsed': collapsed,
        'collapsible--expanded': !collapsed,
      })}
      title={title}
    >
      <button
        className="collapsible__toggle"
        type="button"
        onClick={() => { setCollapsed(!collapsed); }}
      >
        <div className="collapsible__toggle__title">
          {title}
        </div>
        <div className="collapsible__toggle__icon">
          {collapsed
            ? <Icon icon="hook-light-down" className="collapsible__toggle__icon--down" />
            : <Icon icon="hook-light-up" className="collapsible__toggle__icon--up" />
          }
        </div>
      </button>
      <div className="collapsible__content">
        {children}
      </div>
    </div>
  );
};

export default Collapsible;

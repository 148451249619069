import axios from 'axios';

export function loadData(_items) {
  let allData = {};

  return new Promise((resolve, reject) => {
    let loaded = 0;
    const items = (Array.isArray(_items) ? _items : [_items]);

    items.forEach((item) => {
      const { url, sourceKey, key } = ((_item) => {
        if (typeof _item === 'string') {
          return { url: _item };
        }
        if (typeof _item === 'object') {
          return _item;
        }
        return null;
      })(item);

      axios.get(url)
        .then((res) => {
          let data;
          if (sourceKey) {
            data = res.data[sourceKey];
          } else {
            // eslint-disable-next-line prefer-destructuring
            data = res.data;
          }

          if (key) {
            allData[key] = data;
          } else {
            allData = {
              ...allData,
              ...data,
            };
          }

          window.console.info('Data loaded', url);
          loaded += 1;
          if (loaded === items.length) {
            window.console.info('All data loaded', allData);
            resolve(allData);
          }
        })
        .catch((error) => {
          window.console.error('Data load error', url, error);
          reject(error);
        });
    });
  });
}

export default { loadData };

import React from 'react';
import { t } from '../../../../../javascripts/utils/withTranslation';
import Icon from '../../../../_particles/icon/Icon';
import BTIDashboardTooltip from '../../../../molecules/bti-dashboard-tooltip/BTIDashboardTooltip';

const SelectCategoryTooltip = ({
  id,
  event,
  headline,
  text,
  url,
  legendItems,
}) => (
  <BTIDashboardTooltip
    id={id || 'select-category-tooltip'}
    event={event || 'click'}
    effect="solid"
    desiredPlace="top"
    noBackground
    clickable
    // overridePosition arguments:
    // (position, currentEvent, currentTarget, node, place, desiredPlace, effect, offset)
    overridePosition={(position, currentEvent, currentTarget, node, place) => {
      if (!(currentEvent instanceof Event)) {
        return position;
      }
      const body = node.querySelector('.bti-map-select-category-tooltip');
      if ((place === 'top' || place === 'bottom') && position.left < 10) {
        const leftDiff = position.left - 10;
        body.style.transform = `translateX(${-leftDiff}px)`;
      } else {
        body.style.transform = null;
      }
      return position;
    }}
  >
    <div className="bti-map-select-category-tooltip">
      <div className="bti-map-select-category-tooltip__body">

        {headline && (
          <div className="bti-map-select-category-tooltip__headline">
            {headline}
          </div>
        )}

        {text && (
          <div className="bti-map-select-category-tooltip__text">
            <p>{text}</p>
          </div>
        )}

      </div>

      {legendItems && (
        <div className="bti-map-select-category-tooltip__aside">

          <div className="bti-map-select-category-tooltip__legend-headline">
            {t('Kategorien')}
          </div>

          <ul className="bti-map-select-category-tooltip__legend">
            {legendItems.map(legendItem => (
              <li
                className="bti-map-select-category-tooltip__legend-item"
                key={legendItem.key}
              >
                <span
                  className="bti-map-select-category-tooltip__legend-item__color"
                  style={{ backgroundColor: legendItem.color }}
                />
                {legendItem.max} - {legendItem.min}
              </li>
            ))}
          </ul>

        </div>
      )}

    </div>
  </BTIDashboardTooltip>
);

export default SelectCategoryTooltip;

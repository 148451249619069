import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { VictoryPie } from 'victory';

import { DIMENSION } from '../../../javascripts/bti/shared/constants';
import { loadHashData, storeHashData } from '../../../javascripts/utils/hash-data';
import { t } from '../../../javascripts/utils/withTranslation';
import getTarget from '../../../javascripts/utils/get-target';

import Icon from '../../_particles/icon/Icon';
import Button from '../../atoms/button/Button';
import BTIDashboardSentence from '../../molecules/bti-dashboard-sentence/BTIDashboardSentence';
import BTIDashboardShareButton from '../../molecules/bti-dashboard-share-button/BTIDashboardShareButton';

import DimensionBackButton from './components/DimensionBackButton';
import DimensionDonutButton from './components/DimensionDonutButton';
import DimensionDonut from './components/DimensionDonut';

import {
  useConfigAndData,
  useDimensionTitles,
} from '../../../javascripts/bti/shared/hooks';


const HASH_MAP = {
  activeDimension: {
    short: 'd',
    type: 'string',
  },
};


const toggleSelectedDetail = (event) => {
  const target = getTarget(event.target, '.bti-country-dashboard__dimension-detail');
  target.classList.toggle('bti-country-dashboard__dimension-detail--selected');
};


const BTICountryDashboard = ({ configUrl, country }) => {
  const initialHashData = useMemo(() => loadHashData(HASH_MAP), []);
  const [activeDimension, setActiveDimension] = useState(initialHashData.activeDimension);
  const isPreview = !!document.querySelector('body > .preview');

  const enableSecondLevel = false;
  const enableMetaDonuts = false;

  /**
   * Load config and data
   */

  const dataKeys = ['countryDashboard', 'categories', 'countries', 'criteria', 'dimensions', 'index', 'text'];
  const [config, data] = useConfigAndData({
    keys: dataKeys,
    urlData: { countryIso3: country },
    configUrl,
  });

  /**
   * Update hash
   */

  useEffect(() => {
    storeHashData(HASH_MAP, { activeDimension });
  }, [activeDimension]);

  /**
   * Compute data
   */

  const dimensionTitles = useDimensionTitles(data.dimensions, data.index);

  /**
   * Render
   */

  if (!config || Object.keys(data).length < dataKeys.length) {
    return (
      <div className="bti-country-dashboard__loading">
        {t('Lade ...')}
      </div>
    );
  }

  const { countryDashboard } = data;

  const region = countryDashboard.META && countryDashboard.META.RegionRef;
  const regionName = countryDashboard.META && countryDashboard.META.Region;
  const regionUrl = region && config.urlPatterns && config.urlPatterns.region
    && config.urlPatterns.region.replace('{regionIso3}', region);
  const countryName = countryDashboard.META && countryDashboard.META.Name;
  const atlasUrl = countryDashboard.META && countryDashboard.META.ATLAS;
  const sentenceText = (countryDashboard.BODY && countryDashboard.BODY['In One Sentence']) || (isPreview && 'PREVIEW - Provide text in API JSON (BODY/In One Sentence)');
  const countryCount = Object.keys(data.countries).length;
  const year = config.defaultYear || 2020;
  const yearData = countryDashboard.YEAR && countryDashboard.YEAR[year];
  // const otherYearData = otherYear && countryDashboard.YEAR && countryDashboard.YEAR[otherYear];
  const dimensions = [DIMENSION.D, DIMENSION.E, DIMENSION.G];

  return (
    <div className="bti-country-dashboard__wrap">

      <div className="bti-country-dashboard__header">

        <div className="bti-country-dashboard__header__headline">
          <div className="bti-country-dashboard__header__headline__text">
            {countryName}
          </div>
          <div className="bti-country-dashboard__header__headline__back">
            <a className="bti-country-dashboard__header__headline__back__link" href={regionUrl}>
              <Icon icon="hook-left" className="bti-country-dashboard__header__headline__back__link__icon" />
              {t('Zur Region {regionName}', { regionName })}
            </a>
          </div>
        </div>

        <div className="bti-country-dashboard__header__meta">
          {dimensions.map((dimension) => {
            const rank = yearData && yearData[dimension] && yearData[dimension].rank;
            const value = yearData && yearData[dimension] && yearData[dimension].value;

            return (
              <div className="bti-country-dashboard__header__meta__dimension" key={dimension}>

                {enableSecondLevel ? (
                  <button
                    className={classNames({
                      'bti-country-dashboard__header__meta__dimension__name': true,
                      'bti-country-dashboard__header__meta__dimension__name--button': true,
                    })}
                    onClick={() => { setActiveDimension(dimension); }}
                  >
                    {dimensionTitles[dimension]}:
                  </button>
                ) : (
                  <span className="bti-country-dashboard__header__meta__dimension__name">
                    {dimensionTitles[dimension]}:
                  </span>
                )}

                <span className="bti-country-dashboard__header__meta__dimension__rank">
                  {t('#{rank} von {total}', { rank, total: countryCount })}
                </span>

                {enableMetaDonuts && (
                  <span className="bti-country-dashboard__header__meta__dimension__chart">
                    <VictoryPie
                      width={200} height={200}
                      radius={({ datum }) => 100 - ((datum.x - 1) * 10)}
                      innerRadius={({ datum }) => 70 + ((datum.x - 1) * 10)}
                      cornerRadius={({ datum }) => (datum.x === 1 ? 30 : 0)}
                      style={{ labels: { display: 'none' } }}
                      colorScale={['currentColor', '#cecece']}
                      data={[
                        { x: 1, y: parseFloat(value) },
                        { x: 2, y: 10 - parseFloat(value) },
                      ]}
                    />
                  </span>
                )}

              </div>
            );
          })}
        </div>
      </div>

      <div className="bti-country-dashboard__charts-and-aside-wrap">

        <div className="bti-country-dashboard__charts-and-aside-wrap__charts">

          <div className="bti-country-dashboard__dimensions">
            {dimensions.map((dimension) => {
              if (activeDimension && dimension !== activeDimension) {
                return null;
              }

              const isActive = activeDimension === dimension;
              const value = yearData && yearData[dimension] && yearData[dimension].value;
              const category = yearData && yearData[dimension] && yearData[dimension].cat;
              const categoryName = data.categories[dimension][category].nam;
              const color = config.colors.countryDashboardColors[dimension][category];

              return (
                <div
                  key={dimension}
                  className={classNames({
                    'bti-country-dashboard__dimensions__dimension': true,
                    'bti-country-dashboard__dimensions__dimension--active': isActive,
                  })}
                >
                  <DimensionDonutButton
                    categoryName={categoryName}
                    color={color}
                    dimensionTitle={dimensionTitles[dimension]}
                    onClick={enableSecondLevel && (
                      () => { setActiveDimension(isActive ? null : dimension); }
                    )}
                    value={value}
                    active={isActive}
                  />

                  {isActive && (
                    <div className="bti-country-dashboard__dimensions__dimension__back-button-container">
                      <DimensionBackButton onClick={() => { setActiveDimension(null); }} />
                    </div>
                  )}
                </div>
              );
            })}

            {activeDimension && (
              <div className="bti-country-dashboard__dimensions__details">
                {Object.keys(yearData[activeDimension].Criteria).map((key) => {
                  const value = yearData[activeDimension].Criteria[key];
                  const criterium = data.criteria[key];
                  return (
                    <div key={key} className="bti-country-dashboard__dimension-detail">
                      <button
                        className="bti-country-dashboard__dimension-detail__donut-container"
                        onClick={toggleSelectedDetail}
                      >
                        <DimensionDonut
                          className="bti-country-dashboard__dimension-detail__donut"
                          classPrefix="bti-country-dashboard__dimension-detail__donut"
                          colorRange={config.colors.indicatorColors}
                          displayValue
                          value={value}
                          renderSize={100}
                        />
                      </button>
                      <div className="bti-country-dashboard__dimension-detail__info">
                        <button
                          className="bti-country-dashboard__dimension-detail__info__title"
                          onClick={toggleSelectedDetail}
                        >
                          {criterium.name}
                          <Icon
                            className="bti-country-dashboard__dimension-detail__info__title__icon"
                            icon="hook-down"
                          />
                        </button>
                        <div className="bti-country-dashboard__dimension-detail__info__text">
                          Maecenas sed diam eget risus varius blandit sit amet non magna.
                          Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
                          Donec ullamcorper nulla non metus auctor fringilla.
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="bti-country-dashboard__dimensions__details__back-button-container">
                  <DimensionBackButton onClick={() => { setActiveDimension(null); }} />
                </div>
              </div>
            )}
          </div>

        </div>

        <div className="bti-country-dashboard__share-button-container bti-country-dashboard__share-button-container--small">
          <BTIDashboardShareButton />
        </div>

        <div className="bti-country-dashboard__charts-and-aside-wrap__aside">

          {sentenceText && (
            <div className="bti-country-dashboard__dashboard-sentence-container">
              <BTIDashboardSentence
                headline={t('In einem Satz')}
                text={sentenceText}
                twitterLinktext={t('Diskutieren Sie mit uns auf Twitter!')}
              />
            </div>
          )}

          {atlasUrl && (
            <div className="bti-country-dashboard__dashboard-atlas-button-container">
              <Button
                link={atlasUrl}
                style="secondary"
                icon="arrow-up-right"
              >
                {t('Mehr über Transformationsprozesse in {countryName} im BTI-Atlas', { countryName })}
              </Button>
            </div>
          )}

        </div>

      </div>

      <div className="bti-country-dashboard__share-button-container bti-country-dashboard__share-button-container--large">
        <BTIDashboardShareButton />
      </div>

    </div>
  );
};

export default BTICountryDashboard;

import React from 'react';
import { VictoryPie } from 'victory';
import { t } from '../../../javascripts/utils/withTranslation';
import Collapsible from '../../atoms/collapsible/ReactCollapsible';
import formatNumber from '../../../javascripts/utils/format-number';

import {
  useConfigAndData,
  useDimensionTitles,
  useDimensionCriteriaSets,
} from '../../../javascripts/bti/shared/hooks';


const Donut = ({
  value,
}) => (
  <VictoryPie
    width={200} height={200}
    radius={({ datum }) => 100 - ((datum.x - 1) * 10)}
    innerRadius={({ datum }) => 70 + ((datum.x - 1) * 10)}
    cornerRadius={({ datum }) => (datum.x === 1 ? 30 : 0)}
    style={{ labels: { display: 'none' } }}
    colorScale={['currentColor', '#cecece']}
    data={[
      { x: 1, y: parseFloat(value) },
      { x: 2, y: 10 - parseFloat(value) },
    ]}
  />
);


const Meta = ({
  value,
  rank,
  total,
}) => (
  <div className="report-dashboard__meta">
    <div className="report-dashboard__meta__item">
      <span className="report-dashboard__meta__value">{formatNumber(parseFloat(value), 2)}</span>
      <span className="report-dashboard__meta__label">{t('auf Skala {min}-{max}', { min: 1, max: 10 })}</span>
    </div>
    <div className="report-dashboard__meta__item">
      <span className="report-dashboard__meta__value">
        <span className="report-dashboard__meta__value__donut">
          <Donut value={parseFloat(value)} />
        </span>
      </span>
      <span className="report-dashboard__meta__label">{t('#{rank} von {total}', { rank, total })}</span>
    </div>
  </div>
);


const Table = ({ children }) => (
  <table className="report-dashboard__table">
    <tbody>
      {children}
    </tbody>
  </table>
);


const TableRow = ({ label, hint, value }) => (
  <tr className="report-dashboard__table__tr">
    <td className="report-dashboard__table__td report-dashboard__table__td--label">
      {hint
        ? (<abbr>{label}</abbr>)
        : label
      }
    </td>
    <td className="report-dashboard__table__td report-dashboard__table__td--value">{formatNumber(parseFloat(value), 2)}</td>
  </tr>
);


const DimensionCollapsible = ({ dimension, data, config }) => {
  const dimensionTitles = useDimensionTitles(data.dimensions, data.index);
  const dimensionCriteriaSets = useDimensionCriteriaSets(data.dimensions, data.index);

  const dimensionTitle = dimensionTitles[dimension];
  const dimensionCriteria = dimensionCriteriaSets[dimension];

  const yearData = data.countryDashboard.YEAR[config.defaultYear];

  let allCriteriaData = {};
  Object.values(yearData).forEach((dimensionData) => {
    allCriteriaData = { ...allCriteriaData, ...dimensionData.Criteria };
  });

  return (
    <Collapsible key={dimension} title={dimensionTitle}>
      <Meta
        value={yearData[dimension].value}
        rank={yearData[dimension].rank}
        total={config.totalCountryCount}
      />
      <Table>
        {dimensionCriteria.map((criterium) => {
          const criteriumName = data.criteria[criterium].name;
          const criteriumAbbr = data.criteria[criterium].abbr;
          return (
            <TableRow
              key={criterium}
              label={criteriumAbbr || criteriumName}
              hint={criteriumAbbr && criteriumName}
              value={allCriteriaData[criterium]}
            />
          );
        })}
      </Table>
    </Collapsible>
  );
};


const BTIReportDashboard = ({
  configUrl,
  country,
}) => {
  /**
   * Load config and data
   */

  const [config, data] = useConfigAndData({
    keys: ['countryDashboard', 'index', 'dimensions', 'criteria'],
    urlData: { countryIso3: country },
    configUrl,
  });

  /**
   * Render
   */

  if (
    !config
    || !data.countryDashboard
    || !data.index
    || !data.dimensions
    || !data.criteria
  ) {
    return (
      <div className="report-dashboard__loading">
        {t('Lade ...')}
      </div>
    );
  }

  const yearData = data.countryDashboard.YEAR[config.defaultYear];

  let allCriteriaData = {};
  Object.values(yearData).forEach((dimensionData) => {
    allCriteriaData = { ...allCriteriaData, ...dimensionData.Criteria };
  });

  return (
    <div className="report-dashboard">
      <div className="report-dashboard__level1">

        <DimensionCollapsible dimension="D" data={data} config={config} />
        <DimensionCollapsible dimension="E" data={data} config={config} />
        <DimensionCollapsible dimension="G" data={data} config={config} />

      </div>
    </div>
  );
};

export default BTIReportDashboard;

import React from 'react';

import { t } from '../../../javascripts/utils/withTranslation';

import { useConfigAndData } from '../../../javascripts/bti/shared/hooks';

import BackgroundSVG01 from './images/country-figures-01.svg';
import BackgroundSVG02 from './images/country-figures-02.svg';
import BackgroundSVG03 from './images/country-figures-03.svg';
import BackgroundSVG04 from './images/country-figures-04.svg';
import BackgroundSVG05 from './images/country-figures-05.svg';
import BackgroundSVG06 from './images/country-figures-06.svg';

const ACTIVE_IMAGE_SRC = 'images/bti/country-figures-active.png';

const BACKGROUND_SVGS = {
  1: BackgroundSVG01,
  2: BackgroundSVG02,
  3: BackgroundSVG03,
  4: BackgroundSVG04,
  5: BackgroundSVG05,
  6: BackgroundSVG06,
};

const DotsBackground = ({
  index,
}) => {
  const BackgroundSVG = BACKGROUND_SVGS[index];
  return (
    <div style={{
      display: 'block',
      height: 62,
      width: 298,
    }}>
      <BackgroundSVG
        height="62"
        width="298"
        alt=""
      />
    </div>
  );
};


const KeyIndicatorRow = ({
  config,
  indicator,
  index,
}) => {
  if (!indicator.value || indicator.value === '-') {
    return null;
  }

  const position = ((indicator.value - indicator.min) / (indicator.max - indicator.min)) * 100;
  const padding = 6;
  const range = (100 - 2 * padding);
  const paddedPosition = padding + (position / 100 * range);

  return (
    <tr className="bti-country-figures__tr">
      <td className="bti-country-figures__td">
        <span
          className="bti-country-figures__td__attribute"
          dangerouslySetInnerHTML={{ __html: indicator.name }}
        />
        <span className="bti-country-figures__td__value">{indicator.value} {indicator.unit}</span>
      </td>
      <td className="bti-country-figures__td">
        <div className="bti-country-figures__figure">
          <DotsBackground index={index + 1} />
          <img
            className="bti-country-figures__range-active"
            src={config.assets && (config.assets + ACTIVE_IMAGE_SRC)}
            style={{ left: `${paddedPosition}%` }}
            alt=""
          />
        </div>
      </td>
    </tr>
  );
};


const BTICountryFigures = ({ configUrl, country }) => {
  /**
   * Load config and data
   */

  const [config, data] = useConfigAndData({
    keys: ['countryDashboard'],
    urlData: { countryIso3: country },
    configUrl,
  });

  /**
   * Render
   */

  if (
    !config
    || !data.countryDashboard
  ) {
    return (
      <div className="bti-country-figures__loading">
        {t('Lade ...')}
      </div>
    );
  }

  const keyIndicators = data.countryDashboard.BODY['Key Indicators'];

  return (
    <table className="bti-country-figures__table">
      <tbody>
        <tr className="bti-country-figures__tr">
          <th className="bti-country-figures__th">
            <span className="bti-country-figures__th__text">{t('Kennzahlen')}</span>
          </th>
          <th className="bti-country-figures__th">
            <span className="bti-country-figures__th__text">{t('Gesamtvergleich')}</span>
          </th>
        </tr>
        {keyIndicators.map(
          (indicator, index) => <KeyIndicatorRow
            key={indicator.name}
            config={config}
            indicator={indicator}
            index={index}
          />,
        )}
      </tbody>
    </table>
  );
};

export default BTICountryFigures;

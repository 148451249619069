import React, { useEffect, useState } from 'react';
import { t } from '../../../javascripts/utils/withTranslation';
import { useConfigAndData } from '../../../javascripts/bti/shared/hooks';
import Table from './components/Table';
import {
  FooterArea,
  FooterAreaList,
  FooterAreaListWrap,
  FooterAreaListItem,
} from './components/Footer/components/FooterArea';
import Footer from './components/Footer';
import Icon from '../../_particles/icon/Icon';


const BTIRankingTable = ({ configUrl, dimension }) => {
  const [tableData, setTableData] = useState([]);
  const [criteria, setCriteria] = useState({});
  const [legends, setLegends] = useState({});

  /**
   * Load config and data
   */

  const [config, data] = useConfigAndData({
    configUrl,
    keys: ['rankingTable', 'rankingDownloads', 'categories', 'countries', 'criteria', 'text'],
    urlData: { rankingDimension: dimension },
  });

  /**
   * Compile flattened table row data
   */

  useEffect(() => {
    if (!config || !data.countries || !data.rankingTable || !data.categories || !data.text) {
      return;
    }
    const year = config.defaultYear || 2020;
    const base = data.rankingTable.YEAR[year][dimension];
    const newCriteria = {};

    const legendLabels = data.text.legendLabels[dimension] || data.text.legendLabels.default;
    const newLegends = {
      default: {
        items: Object.keys(legendLabels).map(key => ({
          key,
          name: legendLabels[key],
          color: config.colors.indexColors[dimension][key],
        })),
      },
    };

    setTableData(Object.keys(data.countries).map((iso3) => {
      const country = data.countries[iso3];
      const countryValue = parseFloat(base.VAL[iso3]);
      const countryCAT = parseInt(base.CAT[iso3], 10);

      const row = {
        name: country.name,
        countryUrl: config.urlPatterns.country.replace('{countryIso3}', iso3),
        rank: base.RNK[iso3],
        index: countryValue,
        index_CAT: countryCAT,
        index_color: config.colors.indexColors[dimension][countryCAT],
        // index_tip: data.categories[dimension][countryCAT].nam,
        index_tip: legendLabels[countryCAT],
      };

      Object.keys(base.CRITERIA).forEach((criterium) => {
        newCriteria[criterium] = data.criteria[criterium];
        const critValue = parseFloat(base.CRITERIA[criterium].VAL[iso3]);
        const critCAT = parseInt(base.CRITERIA[criterium].CAT[iso3], 10);

        let critColors = config.colors.indexColors[dimension];
        let critLegendLabels;

        // Criterium specific colors & legend
        if (config.colors.indexColors[criterium]) {
          critColors = config.colors.indexColors[criterium];
          if (!legends[criterium] && data.text.legendLabels[criterium]) {
            critLegendLabels = data.text.legendLabels[criterium];

            // Add legend
            newLegends[criterium] = {
              items: Object.keys(critLegendLabels).map(key => ({
                key,
                name: critLegendLabels[key],
                color: critColors[key],
              })),
            };

            // Add legend index
            newCriteria[criterium].legendIndex = Object.values(newLegends).length - 1;
          }
        }

        row[`criterium_${criterium}`] = critValue;
        row[`criterium_${criterium}_CAT`] = critCAT;
        row[`criterium_${criterium}_color`] = critColors[critCAT];
        row[`criterium_${criterium}_tip`] = (critLegendLabels && critLegendLabels[critCAT]) || legendLabels[critCAT];
      });
      return row;
    }));

    setCriteria(newCriteria);
    setLegends(newLegends);
  }, [config, data.countries, data.categories, data.rankingTable, data.text]);


  /**
   * Render
   */

  if (
    !config
    || !tableData
    || !Object.keys(criteria).length
    || !data.text
  ) {
    return (
      <div className="bti-ranking-table__loading">
        {t('Lade ...')}
      </div>
    );
  }

  const downloadItems = data.rankingDownloads && data.rankingDownloads[dimension];

  return (
    <div className="bti-ranking-table__wrap">

      <Table
        tableData={tableData}
        criteria={criteria}
        hyphensDict={config.hyphensDict}
      />

      <Footer>

        {legends && (
          <FooterArea title={t('Legende')}>
            <FooterAreaListWrap>
            {Object.keys(legends).map((legendKey, legendIndex) => (
              <FooterAreaList key={legendKey}>
                <div className="bti-ranking-table__footer-legend">
                  {(legendIndex > 0) && (
                    <span className="bti-ranking-table__footer-legend-index-badge bti-ranking-table__legend-index-badge">
                      {legendIndex}
                    </span>
                  )}
                  {legends[legendKey].items.map((legendItem, legendItemIndex) => (
                    <FooterAreaListItem key={legendItemIndex}>
                      <div className="bti-ranking-table__footer-legend-item">
                        <span
                          className="bti-ranking-table__footer-legend-item__color"
                          style={{ backgroundColor: legendItem.color }}
                        />
                        <span className="bti-ranking-table__footer-legend-item__text">
                          {legendItem.name}
                        </span>
                      </div>
                    </FooterAreaListItem>
                  ))}
                </div>
              </FooterAreaList>
            ))}
            </FooterAreaListWrap>
          </FooterArea>
        )}


        {downloadItems && downloadItems.length && (
          <FooterArea title={t('Downloads')}>
            <FooterAreaList>
              {downloadItems.map((downloadItem, index) => (
                <FooterAreaListItem key={index}>
                  <div className="bti-ranking-table__footer-download-item">
                    <a
                      className="bti-ranking-table__footer-download-item__link"
                      href={downloadItem.url}
                    >
                      <Icon icon="download" className="bti-ranking-table__footer-download-item__link__icon" />
                      {downloadItem.name}
                    </a>
                  </div>
                </FooterAreaListItem>
              ))}
            </FooterAreaList>
          </FooterArea>
        )}

      </Footer>

    </div>
  );
};

export default BTIRankingTable;

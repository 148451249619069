import React from 'react';
import formatNumber from '../../../../../../../javascripts/utils/format-number';

export const DefaultCell = ({ cellData }) => (
  <div className="bti-ranking-table-cell">
    {cellData}
  </div>
);

export const CountryNameCell = ({ cellData, rowData }) => (
  <div className="bti-ranking-table-cell">
    {rowData.countryUrl
      ? <a className="bti-ranking-table__link" href={rowData.countryUrl}>{cellData}</a>
      : cellData
    }
  </div>
);

export const IndexCell = ({ cellData, rowData, cellParams }) => {
  const color = rowData[cellParams.colorField];
  return (
    <div className="bti-ranking-table-cell bti-ranking-table-index-cell">
      <div className="bti-ranking-table-index-cell__text">{formatNumber(parseFloat(cellData), 2)}</div>
      <div className="bti-ranking-table-index-cell__color-wrap">
        <div className="bti-ranking-table-index-cell__color"
          style={{
            backgroundColor: color,
            width: `${(parseFloat(cellData) / 10) * 100}%`,
          }} />
      </div>
    </div>
  );
};

export const CriteriumCell = ({ cellData, rowData, cellParams }) => {
  const color = rowData[cellParams.colorField];
  return (
    <div className="bti-ranking-table-cell bti-ranking-table-criterium-cell">
      <div className="bti-ranking-table-criterium-cell__text">{formatNumber(parseFloat(cellData), 1)}</div>
      <div className="bti-ranking-table-criterium-cell__color" style={{ backgroundColor: color }} />
    </div>
  );
};

const Cell = (cellProps) => {
  if (cellProps.cellComponent) {
    return cellProps.cellComponent(cellProps);
  }
  if (cellProps.column.cellComponent) {
    return cellProps.column.cellComponent({
      ...cellProps,
      cellParams: cellProps.column.cellParams,
    });
  }
  return DefaultCell(cellProps);
};

export default Cell;

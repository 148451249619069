import React from 'react';
import classNames from 'classnames';
import IconLink from '../../atoms/icon-link/IconLink';
import { t } from '../../../javascripts/utils/withTranslation';

const BTIDashboardShareButton = ({ reversed }) => (
  <IconLink
    reversed={reversed}
    type="button"
    icon="share"
    className={classNames('bti-dashboard-share-button', {
      'bti-dashboard-share-button--reversed': reversed,
      'js-share-dialog': true,
    })}
  >
    {t('Jetzt Darstellung teilen')}
  </IconLink>
);

export default BTIDashboardShareButton;

export const listReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.item].sort();
    case 'removeIndex':
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1),
      ].sort();
    case 'removeValue':
      return state.filter(item => (item !== action.item)).sort();
    case 'toggle':
      if (state.includes(action.item)) {
        return state.filter(item => (item !== action.item)).sort();
      }
      return [...state, action.item].sort();
    case 'reset':
      return [];
    default:
      throw new Error();
  }
};

export default { listReducer };

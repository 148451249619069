/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */

const LegacyValidation = (field) => {
  let valid = true;
  const val = field.value;
  const type = field.getAttribute('type');
  const chkbox = (type === 'checkbox' || type === 'radio');
  const required = field.getAttribute('required');
  const minlength = field.getAttribute('minlength');
  const maxlength = field.getAttribute('maxlength');
  let pattern = field.getAttribute('pattern');

  // disabled fields should not be validated
  if (field.disabled) {
    return valid;
  }

  // value required?
  valid = valid && (
    !required || (chkbox && field.checked) || (!chkbox && val !== '')
  );

  // minlength or maxlength set?
  valid = valid && (
    chkbox || ((!minlength || val.length >= minlength) && (!maxlength || val.length <= maxlength))
  );

  // test pattern
  if (valid && pattern) {
    pattern = new RegExp(pattern);
    valid = pattern.test(val);
  }

  return valid;
};


const FormErrorEvent = new CustomEvent('formerror');
const FormValidEvent = new CustomEvent('formvalid');


export const setHasError = (element, hasError, hadFocus) => {
  const elHadFocus = typeof hadFocus !== 'undefined' ? hadFocus : !!element.querySelector('[data-had-focus]');
  const hadError = !!element.getAttribute('data-has-error');
  const validityHasChanged = hasError !== hadError;

  if (hasError) {
    element.setAttribute('data-has-error', true);
    if (elHadFocus) {
      element.setAttribute('data-has-visible-error', true);
    }
  } else {
    element.removeAttribute('data-has-error');
    if (elHadFocus) {
      element.removeAttribute('data-has-visible-error');
    }
  }

  const initialError = element.getAttribute('data-initial-error');

  if (validityHasChanged && initialError !== null) {
    if (hasError) {
      element.dispatchEvent(FormErrorEvent);
    } else {
      element.dispatchEvent(FormValidEvent);
    }
  } else if (initialError === null) {
    element.setAttribute('data-initial-error', hasError);
  }
};


const applyValidityClasses = (element, valid, classPrefix) => {
  const errorClass = classPrefix ? `${classPrefix}--invalid` : 'invalid';
  const validClass = classPrefix ? `${classPrefix}--valid` : 'valid';
  const validityHasChanged = (valid !== element.classList.contains(validClass));
  if (valid) {
    element.classList.add(validClass);
    element.classList.remove(errorClass);
  } else {
    element.classList.add(errorClass);
    element.classList.remove(validClass);
  }
  return validityHasChanged;
};


export const updateGroupValidity = (element, classPrefix) => {
  const isCollapsed = !!element.closest('[aria-expanded=false]');

  // const hasError = !isCollapsed && !!element.querySelector('[data-has-error]');
  let hasError = false;
  let hasVisibleError = false;

  if (!isCollapsed) {
    element.querySelectorAll('[data-has-error]').forEach((candidate) => {
      if (!candidate.closest('[aria-expanded=false]')) {
        hasError = true;
      }
    });
    element.querySelectorAll('[data-has-visible-error]').forEach((candidate) => {
      if (!candidate.closest('[aria-expanded=false]')) {
        hasVisibleError = true;
      }
    });
  }

  const hadFocus = !!element.querySelector('[data-had-focus]') || element.closest('form').hasAttribute('data-form-show-all-errors');
  const isVisiblyValid = hadFocus && !hasVisibleError;

  const initialError = element.getAttribute('data-initial-error');

  const hadError = element.getAttribute('data-child-has-error') === 'true';
  element.setAttribute('data-child-has-error', hasError);

  if ((hasError !== hadError) && initialError !== null) {
    if (hasError) {
      element.dispatchEvent(FormErrorEvent);
    } else {
      element.dispatchEvent(FormValidEvent);
    }
  } else if (initialError === null) {
    element.setAttribute('data-initial-error', hasError);
  }

  if (hadFocus) {
    applyValidityClasses(element, isVisiblyValid, classPrefix);
  }
};


const updateFormValidity = (form) => {
  const groups = [
    { selector: 'label', prefix: 'label' },
    { selector: '.form__group', prefix: 'form__group' },
    { selector: '.form-group', prefix: 'form-group' },
    { selector: '.form__row', prefix: 'form__row' },
    { selector: '.form__section', prefix: 'form__section' },
    { selector: 'fieldset', prefix: 'fieldset' },
  ];
  groups.forEach((group) => {
    form.querySelectorAll(group.selector).forEach(el => updateGroupValidity(el, group.prefix));
  });
  updateGroupValidity(form, 'form');
};

const validateField = (field) => {
  // ignore buttons, fieldsets, etc.
  if (field.nodeName !== 'INPUT' && field.nodeName !== 'TEXTAREA' && field.nodeName !== 'SELECT') {
    return;
  }

  // is native browser validation available?
  if (typeof field.willValidate !== 'undefined') {
    // native validation available
    if (field.nodeName === 'INPUT' && field.type !== field.getAttribute('type')) {
      // input type not supported! Use legacy JavaScript validation
      field.setCustomValidity(LegacyValidation(field) ? '' : 'error');
    }

    // native browser check
    field.checkValidity();
  } else {
    // native validation not available
    field.validity = field.validity || {};
    // set to result of validation function
    try {
      field.validity.valid = LegacyValidation(field);
    } catch {}
  }

  const hadFocus = field.getAttribute('data-had-focus') || field.closest('form').hasAttribute('data-form-show-all-errors');

  // disabled & invisible fields
  if (field.closest('.disabled') || field.getAttribute('disabled')) {
    try {
      field.validity.valid = true;
    } catch {}
  }

  // set error attribute
  setHasError(field, !field.validity.valid, hadFocus);

  // apply classes
  if (hadFocus) {
    applyValidityClasses(field, field.validity.valid, 'input');
  }
};


const validateForm = (form) => {
  window.console.log('[Form] Validate');

  // loop all fields
  form.querySelectorAll('input, textarea, select').forEach((field) => {
    validateField(field);
  });

  updateFormValidity(form);

  return (form.getAttribute('data-child-has-error') === 'false');
};


const setSubmitButtonsDisabled = (form, disabled) => {
  const buttons = form.querySelectorAll('[type=submit]');
  if (disabled) {
    // buttons.forEach(submit => submit.setAttribute('disabled', true));
    buttons.forEach(submit => submit.classList.add('button--disabled'));
  } else {
    // buttons.forEach(submit => submit.removeAttribute('disabled'));
    buttons.forEach(submit => submit.classList.remove('button--disabled'));
  }
};

const scrollDoc = document.scrollingElement || document.documentElement;

const scrollTo = (destOffset, duration) => {
  const diffOffset = destOffset - scrollDoc.scrollTop;
  const partDist = diffOffset / duration * 1;

  if (duration <= 0) return;
  setTimeout(() => {
    scrollDoc.scrollTop += partDist;
    if (scrollDoc.scrollTop === destOffset) return;
    scrollTo(destOffset, duration - 1);
  }, 1);
};

const initFormValidation = (form) => {
  form.setAttribute('noValidate', true);
  form.addEventListener('submit', (event) => {
    window.console.log('[Form] Attempt to submit');
    form.setAttribute('data-form-show-all-errors', true);
    if (!validateForm(form) && event.preventDefault) {
      window.console.log('[Form] Prevent submit (invalid)');
      event.preventDefault();

      const firstErrorElement = form.querySelector('[data-child-has-error="true"]');
      if (firstErrorElement) {
        scrollTo(scrollDoc.scrollTop + firstErrorElement.getBoundingClientRect().top - 20, 50);
      }
    }
  });

  const validate = (event) => {
    validateField(event.target);
    updateFormValidity(form);
  };

  const setFocused = (event) => {
    event.target.setAttribute('data-had-focus', true);
  };

  const setFocusedAndValidate = (event) => {
    setFocused(event);
    validate(event);
  };

  form.querySelectorAll('input, textarea, select').forEach((field) => {
    ['click'].forEach((eventType) => {
      field.addEventListener(eventType, setFocused);
    });
    ['change', 'blur'].forEach((eventType) => {
      field.addEventListener(eventType, setFocusedAndValidate);
    });
    ['keyup'].forEach((eventType) => {
      field.addEventListener(eventType, validate);
    });
  });

  form.querySelectorAll('.select-button input').forEach((field) => {
    field.addEventListener('click', setFocusedAndValidate);
  });

  const initialValid = validateForm(form);

  form.addEventListener('formvalid', () => {
    setSubmitButtonsDisabled(form, false);
  });
  form.addEventListener('formerror', () => {
    setSubmitButtonsDisabled(form, true);
  });
  form.addEventListener('formshouldvalidate', () => {
    validateForm(form);
  });

  setSubmitButtonsDisabled(form, !initialValid);
};


document.querySelectorAll('form.form, .form form').forEach((form) => {
  initFormValidation(form);
});


// Repeatables
document.querySelectorAll('[data-form-repeat]').forEach((button) => {
  button.addEventListener('click', (event) => {
    event.preventDefault();
    const selector = button.getAttribute('data-form-repeat');
    const wrap = button.closest('form').querySelector(selector);
    const element = wrap.querySelector('.form-repeatables__repeatable');
    const clone = element.cloneNode(true);
    clone.querySelectorAll('input').forEach((input) => {
      input.value = '';
    });
    wrap.appendChild(clone);
  });
});


// Dependencies
document.querySelectorAll('[data-depends-on]').forEach((dependent) => {
  const selector = dependent.getAttribute('data-depends-on');
  const dependency = dependent.closest('form').querySelector(selector);
  const update = () => {
    if (dependency.value && dependency.value !== '0') {
      dependent.removeAttribute('disabled');
    } else {
      dependent.setAttribute('disabled', true);
    }
  };
  update();
  dependency.addEventListener('change', update);
  dependency.addEventListener('keyup', update);
  dependency.addEventListener('blur', update);
});

import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

const BTIDashboardTooltip = props => (
  <ReactTooltip
    className={classNames({
      'bti-dashboard-tooltip': true,
      'bti-dashboard-tooltip--no-background': props.noBackground,
      ...props.classNames,
    })}
    globalEventOff="click"
    overridePosition={(position, currentEvent, currentTarget, node, place) => {
      if (!(currentEvent instanceof Event)) {
        return position;
      }

      const body = node.querySelector('*');
      const width = node.clientWidth;
      const bodyWidth = document.body.clientWidth;
      const right = width + position.left;

      if ((place === 'top' || place === 'bottom') && position.left < 10) {
        const leftDiff = position.left - 10;
        body.style.transform = `translateX(${-leftDiff}px)`;
      } else if ((place === 'top' || place === 'bottom') && right > bodyWidth) {
        const leftDiff = bodyWidth - right - 10;
        body.style.transform = `translateX(${leftDiff}px)`;
      } else {
        body.style.transform = null;
      }

      return position;
    }}
    {...props}
  >
    <div className="bti-dashboard-tooltip__body">
      {props.children}
    </div>
  </ReactTooltip>
);

export default BTIDashboardTooltip;

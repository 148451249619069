const sortArrayByKey = (() => {
  const sorters = {
    string: (a, b) => a.localeCompare(b),
    string2: (a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },

    number: (a, b) => (a - b),
  };

  return (ary, prop, reverse) => {
    if (!ary || typeof ary !== 'object' || !ary.length) {
      return ary;
    }
    const type = typeof ary[0][prop] || 'string';
    const sorted = ary.sort(
      (a, b) => sorters[type](a[prop], b[prop]),
    );
    return reverse ? sorted.reverse() : sorted;
  };
})();

export default sortArrayByKey;

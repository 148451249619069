import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from '../../../../_particles/icon/Icon';

const CategorySelectButton = ({
  onClick,
  active,
  color,
  title,
}) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleClick = () => {
    if (onClick) {
      onClick.apply();
    }
  };

  const buttonStyles = {};

  if (active) {
    buttonStyles.backgroundColor = color;
  } else if (hover) {
    buttonStyles.boxShadow = `0 0 0 1px ${color} inset`;
    buttonStyles.borderColor = color;
  }

  return (
    <button
      className={classNames({
        'bti-map-category-select-button': true,
        'bti-map-category-select-button--active': active,
        'bti-map-category-select-button--inactive': !active,
        'bti-map-category-select-button--hover': hover,
      })}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={buttonStyles}
    >
      <span
        className="bti-map-category-select-button__color-blob"
        style={{ backgroundColor: color }}
      />
      {title}
      <span className="bti-map-category-select-button__close-icon">
        <Icon icon="close" />
      </span>
    </button>
  );
};

export default CategorySelectButton;

import h from 'hyperscript';
import getVideoId from 'get-video-id';
import ReactDOM from 'react-dom';
import React from 'react';
import { generateIcon as icon } from 'components/_particles/icon/Icon';
import Lightbox from 'components/_particles/lightbox/Lightbox';
import { t } from 'javascripts/utils/withTranslation';

class Figure {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$media = this.$el.querySelector('.figure__media');
    this.$image = this.$el.querySelector('.image__img');
    this.$videoPlay = this.$el.querySelector('.figure__play');
    this.$lightbox = this.$el.querySelector('.figure__lightbox');

    // Constants
    this.IMAGE_HEIGHT_THRESHOLD = 740;
    this.LABELS = {
      expand: t('Bild komplett anzeigen'),
      hide: t('Bild teilweise verbergen'),
    };

    // Init components
    if (this.isExpandable()) {
      this.initExpand();
    }

    // Add events
    if (this.$videoPlay) {
      this.$videoPlay.addEventListener('click', this.onVideoClick.bind(this));
    }

    if (this.$lightbox) {
      this.$lightbox.addEventListener('click', this.onLightboxClick.bind(this));
    }
  }

  initExpand() {
    const isExpandedByDefault = this.$el.classList.contains('figure--expanded');

    // Add class
    this.$el.classList.add('figure--expandable');

    // Generate label
    const $label = h(
      'span.figure__expand-button-label',
      isExpandedByDefault ? this.LABELS.hide : this.LABELS.expand,
    );

    // Generate icon
    const $icon = icon({
      icon: 'hook-light-down',
      className: 'figure__expand-button-icon',
    });

    // Generate expand button
    this.$expandButton = h(
      'button.figure__expand-button',
      {
        type: 'button',
        onclick: () => {
          const isExpanded = this.$expandButton.getAttribute('aria-expanded') === 'true';
          this.$el.classList[isExpanded ? 'remove' : 'add']('figure--expanded');
          this.$expandButton.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
          $label.textContent = isExpanded ? this.LABELS.expand : this.LABELS.hide;
        },
        attrs: {
          'aria-expanded': isExpandedByDefault ? 'true' : 'false',
        },
      },
      $label,
      $icon,
    );

    // Insert button
    this.$el.appendChild(this.$expandButton);
  }

  onVideoClick(event) {
    event.preventDefault();

    const url = this.$videoPlay.href;
    const { id, service } = getVideoId(url);
    let iframeUrl = null;

    if (id && service === 'youtube') {
      // Construct YouTube url, add rel=0 to disable related videos
      iframeUrl = `https://www.youtube.com/embed/${id}?rel=0&autoplay=1&enablejsapi=1`;
    } else {
      iframeUrl = url;
    }

    if (iframeUrl) {
      // Construct iframe player
      const $iframe = h('.figure__iframe-wrapper', h('iframe.figure__iframe', {
        src: iframeUrl,
        allowFullscreen: true,
        msAllowFullscreen: true,
        webkitAllowFullscreen: true,
        mozAllowFullscreen: true,
      }));

      // Replace with iframe
      this.$videoPlay.parentNode.replaceChild($iframe, this.$videoPlay);
    }
  }

  onLightboxClick(event) {
    // Prevent navigation
    event.preventDefault();

    // Prepare mount point
    const $mountPoint = h('#lightbox-mountpoint');
    document.body.appendChild($mountPoint);

    // Get caption & copyright
    const $caption = this.$el.querySelector('.figure__caption');
    const $copyright = this.$el.querySelector('.copyright__text');

    // Get image
    const image = {
      src: this.$image.src,
      link: this.$lightbox.href,
      alt: this.$image.alt,
      caption: $caption && $caption.innerText,
      source: $copyright && $copyright.innerText,
      index: 0,
      width: this.$lightbox.getAttribute('data-width') || this.$image.width,
      height: this.$lightbox.getAttribute('data-height') || this.$image.height,
    };

    // Render dialog
    ReactDOM.render(<Lightbox images={[image]} currentImage={0} onClose={() => {
      ReactDOM.unmountComponentAtNode($mountPoint);
      this.$lightbox.focus();
    }} />, $mountPoint);
  }

  isExpandable() {
    return this.getMediaType() === 'image' && this.getImageHeight() > this.IMAGE_HEIGHT_THRESHOLD;
  }

  getImageHeight() {
    return parseInt(this.$el.querySelector('.image__img').getAttribute('height'), 10);
  }

  getMediaType() {
    return this.$el.querySelector('.figure__play') ? 'video' : 'image';
  }
}

document.querySelectorAll('.js-figure').forEach($el => new Figure($el));

import React from 'react';
import ReactDOM from 'react-dom';

import BTICountryFigures from './BTICountryFigures';


export const initBTICountryFigures = (container) => {
  window.console.log(container);
  container.classList.add('bti-country-figures--initialized');

  const configUrl = container.getAttribute('data-bti-country-figures-config-url');
  const country = container.getAttribute('data-bti-country-figures-country');

  ReactDOM.render((
    <BTICountryFigures
      configUrl={configUrl}
      country={country}
    />
  ), container);
};

export const initAllBTICountryFigures = () => {
  document.querySelectorAll('.bti-country-figures:not(.bti-country-figures--initialized)').forEach(initBTICountryFigures);
};

initAllBTICountryFigures();

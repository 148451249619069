import React from 'react';
import Collapsible from '../../../../../../atoms/collapsible/ReactCollapsible';

export const FooterArea = ({ title, children }) => (
  <div className="bti-ranking-table-footer-area">
    <Collapsible
      title={(
        <div className="bti-ranking-table-footer-area__title">
          {title}
        </div>
      )}
    >
      {children}
    </Collapsible>
  </div>
);

export const FooterAreaListWrap = ({ children }) => (
  <div className="bti-ranking-table-footer-area__list-wrap">
    {children}
  </div>
);

export const FooterAreaList = ({ children }) => (
  <ul className="bti-ranking-table-footer-area__list">
    {children}
  </ul>
);

export const FooterAreaListItem = ({ children }) => (
  <li className="bti-ranking-table-footer-area__list__item">
    {children}
  </li>
);

export default {
  FooterArea,
  FooterAreaList,
  FooterAreaListWrap,
  FooterAreaListItem,
};

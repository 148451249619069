import Masonry from 'masonry-layout';

document.querySelectorAll('.team-list__items').forEach((grid) => {
  const msnry = new Masonry(grid, {
    columnWidth: '.team-list__item-sizer',
    itemSelector: '.team-list__item',
    percentPosition: true,
    gutter: 30,
  });

  msnry.once('layoutComplete', () => {
    grid.classList.add('loaded');
  });

  msnry.layout();
});

import React from 'react';
import Icon from '../../_particles/icon/Icon';
import { getSharingLink } from '../social-sharing/SocialSharing';
import { t } from '../../../javascripts/utils/withTranslation';

const BTIDashboardSentence = ({
  headline,
  text,
  twitterLinktext,
}) => (
  <div className="bti-dashboard-sentence">
    {headline && (
      <div className="bti-dashboard-sentence__headline">{headline}</div>
    )}
    {text && (
      <div className="bti-dashboard-sentence__text">{text}</div>
    )}
    {twitterLinktext && (
      <div className="bti-dashboard-sentence__twitter">
        <a
          href={getSharingLink({
            service: 'twitter',
            title: text,
          })}
          className="bti-dashboard-sentence__twitter-link js-popup"
          {...{
            'aria-label': t('Auf {name} teilen.', { name: 'Twitter' }),
            'data-name': 'Twitter',
          }}
        >
          <Icon icon="twitter-outline" className="bti-dashboard-sentence__twitter-link__icon" />
          <span className="bti-dashboard-sentence__twitter-link__text">{twitterLinktext}</span>
        </a>
      </div>
    )}
  </div>
);

export default BTIDashboardSentence;

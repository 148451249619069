import React from 'react';
import ReactDOM from 'react-dom';

import SocialSharing from './SocialSharing';


export const initSocialSharing = (container) => {
  container.classList.add('js-social-sharing--initialized');

  ReactDOM.render((
    <SocialSharing
      services={window.shareServices}
    />
  ), container);
};

export const initAllSocialSharing = () => {
  document.querySelectorAll('.js-social-sharing:not(.js-social-sharing--initialized)').forEach(initSocialSharing);
};

initAllSocialSharing();

import React from 'react';
import ScrollArea from 'react-scrollbar';
import classNames from 'classnames';


const MapScroller = ({ children, className, fullWidth }) => (
  <ScrollArea
    className={classNames(className, {
      'bti-map-map-scroller': true,
      'bti-map-map-scroller--full-width': fullWidth,
    })}
    contentClassName="bti-map-map-scroller__content"
    horizontal
    vertical={false}
    // smoothScrolling
    // stopScrollPropagation
  >
    {children}
  </ScrollArea>
);

export default MapScroller;

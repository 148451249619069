import React from 'react';
import BTIDashboardTooltip from '../../../../../../molecules/bti-dashboard-tooltip/BTIDashboardTooltip';


const Tooltip = ({ text }) => (
  <BTIDashboardTooltip
    id="bti-ranking-table-tooltip"
    effect="float"
    classNames={{
      'bti-ranking-table-tooltip': true,
      'bti-ranking-table-tooltip--hidden': !text,
    }}
  >
    <div className="bti-ranking-table-tooltip__body">
      {text}
    </div>
  </BTIDashboardTooltip>
);

export default Tooltip;

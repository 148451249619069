import React from 'react';
import ReactDOM from 'react-dom';

import BTIRankingTable from './BTIRankingTable';


export const initBTIRankingTable = (container) => {
  container.classList.add('bti-ranking-table--initialized');

  const configUrl = container.getAttribute('data-bti-ranking-table-config-url');
  const dimension = container.getAttribute('data-bti-ranking-table-dimension');

  ReactDOM.render((
    <BTIRankingTable
      configUrl={configUrl}
      dimension={dimension}
    />
  ), container);
};

export const initBTIRankingTables = () => {
  document.querySelectorAll('.bti-ranking-table:not(.bti-ranking-table--initialized)').forEach(initBTIRankingTable);
};

initBTIRankingTables();

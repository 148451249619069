import { useMemo, useEffect, useState } from 'react';
import { t } from '../../utils/withTranslation';
import { loadConfigAndData } from './loadConfigAndData';

export function useCountryOptions(countries) {
  return useMemo(() => countries && Object.values(countries).map(
    country => ({ value: country.ISO3, label: country.name }),
  ), [countries]);
}

export function useRegionOptions(regions) {
  return useMemo(() => regions && Object.values(regions).map(
    region => ({ value: region.ISO3, label: region.name }),
  ), [regions]);
}

export function useCountryAndRegionOptions(countries, regions, region) {
  return useMemo(() => {
    const countryOptions = countries ? Object.values(countries).filter(
      item => ((!region || region === item.region)),
    ).map(
      item => ({ value: item.ISO3, label: item.name }),
    ) : [];

    if (region) {
      return countryOptions;
    }

    const regionOptions = regions ? Object.values(regions).map(
      item => ({ value: item.ISO3, label: item.name, isRegion: true }),
    ) : [];

    return [{
      label: t('Regionen'),
      options: regionOptions,
    }, {
      label: t('Länder'),
      options: countryOptions,
    }];
  }, [countries, regions, region]);
}

export function useDimensionTitles(dimensions, index) {
  return useMemo(() => (dimensions && index && {
    D: (dimensions.D && dimensions.D.name) || 'D',
    E: (dimensions.E && dimensions.E.name) || 'E',
    L: (dimensions.E && dimensions.L.name) || 'L',
    P: (dimensions.P && dimensions.P.name) || 'P',
    G: (index.G && index.G.name) || 'G',
  }), [dimensions, index]);
}

export function useDimensionCriteriaSets(dimensions) {
  return useMemo(() => (dimensions && {
    D: dimensions.D && dimensions.D.criteria,
    E: dimensions.E && dimensions.E.criteria,
    L: dimensions.L && dimensions.L.criteria,
    P: dimensions.P && dimensions.P.criteria,
    G: dimensions.L && dimensions.P && dimensions.L.criteria && dimensions.P.criteria
      && [...dimensions.L.criteria, ...dimensions.P.criteria],
  }), [dimensions]);
}

export function useActiveDimensionTitle(dimensionTitles, activeDimension) {
  return useMemo(() => (
    dimensionTitles && activeDimension && dimensionTitles[activeDimension]
  ), [dimensionTitles, activeDimension]);
}

export function useConfigAndData({
  configUrl,
  keys,
  urlData,
}) {
  const [config, setConfig] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    loadConfigAndData({
      configUrl,
      keys,
      urlData,
      setConfig,
      setData,
    });
  }, []);

  return [config, data];
}

import React from 'react';
import classNames from 'classnames';
import { VictoryPie } from 'victory';
import Icon from '../../../../_particles/icon/Icon';
import BTIDashboardTooltip from '../../../../molecules/bti-dashboard-tooltip/BTIDashboardTooltip';

const CountryTooltip = ({ id, name, val }) => (
  <BTIDashboardTooltip
    id={id || 'country-tooltip' }
    effect="float"
    className={classNames({
      'bti-dashboard-tooltip': true,
      'bti-dashboard-tooltip--with-donut': !!val,
    })}
    delayHide={50}
  >
    <div className="bti-map-country-tooltip">

      <div className="bti-map-country-tooltip__name">{name}</div>

      {val && (
        <div className="bti-map-country-tooltip__donut">
          <div className="bti-map-country-tooltip__donut__image">
            <VictoryPie
              width={200} height={200}
              radius={({ datum }) => 100 - ((datum.x - 1) * 10)}
              innerRadius={({ datum }) => 70 + ((datum.x - 1) * 10)}
              cornerRadius={({ datum }) => (datum.x === 1 ? 30 : 0)}
              style={{ labels: { display: 'none' } }}
              colorScale={['currentColor', '#cecece']}
              data={[
                { x: 1, y: parseFloat(val) },
                { x: 2, y: 10 - parseFloat(val) },
              ]}
            />
          </div>
          <div className="bti-map-country-tooltip__donut__value">
            {val}
          </div>
        </div>
      )}

      <div className="bti-map-country-tooltip__block-arrow">
        <Icon icon="hook-right" className="bti-map-country-tooltip__block-arrow__icon" />
      </div>

    </div>
  </BTIDashboardTooltip>
);

export default CountryTooltip;

import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-toggle-accordion');

  if ($target) {
    // Stop event here
    event.preventDefault();

    // Get state
    const $slide = $target.closest('.accordion__item');
    const $toggles = $slide.querySelectorAll('.js-toggle-accordion');
    const $content = document.getElementById($toggles[0].getAttribute('aria-controls'));
    const accordionContentVisible = $toggles[0].getAttribute('aria-expanded') === 'true';

    // Switch toggles
    $toggles.forEach($toggle => $toggle.setAttribute('aria-expanded', accordionContentVisible ? 'false' : 'true'));

    // Switch content visiblity
    if (accordionContentVisible) {
      $content.classList.remove('accordion__content--visible');
    } else {
      $content.classList.add('accordion__content--visible');
      $content.focus();
    }
  }
});

const openById = (id) => {
  const $target = document.getElementById(id);

  if ($target && $target.matches('.accordion__item')) {
    const $trigger = $target.querySelector('.js-toggle-accordion');

    // Edge case: Accordion inside Tabs
    if ($target.closest('.tabbed-teasers__panel')) {
      const $panel = $target.closest('.tabbed-teasers__panel');
      const $tabTrigger = document.getElementById($panel.getAttribute('aria-labelledby'));

      if ($tabTrigger) {
        $tabTrigger.click();
      }
    }

    if ($trigger && $trigger.getAttribute('aria-expanded') !== 'true') {
      $trigger.click();
    }
  }
};

// Open on load
if (window.location.hash) {
  openById(window.location.hash.substring(1));
}

window.addEventListener('hashchange', () => {
  openById(window.location.hash.substring(1));
});

import React from 'react';
import ReactDOM from 'react-dom';
import BTICountrySelect from './BTICountrySelect';

document.querySelectorAll('.bti-country-select').forEach((container) => {
  const configUrl = container.getAttribute('data-bti-country-select-config-url');
  ReactDOM.render((
    <BTICountrySelect
      configUrl={configUrl}
      // menuIsOpen
    />
  ), container);
});

const extendedHeader = document.querySelector('.extended-header');
const buttonToggleHeader = document.querySelector('.extended-header__toggle');

if (extendedHeader) {
  // Toggle header
  const toggleHeader = () => {
    extendedHeader.classList.toggle('extended-header--expanded');
    buttonToggleHeader.setAttribute('aria-expanded', extendedHeader.classList.contains('extended-header--expanded'));
  };
  buttonToggleHeader.addEventListener('click', toggleHeader);

  // Toggle submenu
  document.querySelectorAll('.extended-header__nav__li').forEach((wrap) => {
    const toggleButtons = wrap.querySelectorAll('.extended-header__nav__item--has-sub');
    const toggle = () => {
      wrap.classList.add('extended-header__nav__li--expanded');
    };
    toggleButtons.forEach(button => button.addEventListener('click', toggle));
  });

  // Back to level 1
  document.querySelectorAll('.extended-header__nav__li--level1').forEach((wrap) => {
    const level2BackButtons = wrap.querySelectorAll('.extended-header__nav__back--level2');
    const level2Back = () => {
      wrap.classList.remove('extended-header__nav__li--expanded');
    };
    level2BackButtons.forEach(button => button.addEventListener('click', level2Back));
  });

  // Back to level 2
  document.querySelectorAll('.extended-header__nav__li--level2').forEach((wrap) => {
    const level3BackButtons = wrap.querySelectorAll('.extended-header__nav__back--level3');
    const level3Back = () => {
      wrap.classList.remove('extended-header__nav__li--expanded');
    };
    level3BackButtons.forEach(button => button.addEventListener('click', level3Back));
  });
}

import React, { useState, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import BaseTable, { AutoResizer, SortOrder, Column } from 'react-base-table';

import { t } from '../../../../../javascripts/utils/withTranslation';
import sortArrayByKey from '../../../../../javascripts/utils/sortArrayByKey';
import { getScrollBarWidth } from '../../../../../javascripts/utils/getScrollBarWidth';

import Tooltip from './components/Tooltip/index';
import Cell, { CountryNameCell, IndexCell, CriteriumCell } from './components/Cell';

const isFirefox = typeof InstallTrigger !== 'undefined';

const betterHyphens = (string, dict) => {
  if (dict && dict[string]) {
    return dict[string].replace('-', '&shy;');
  }
  return string;
};

const criteriumHeaderTitle = (criterium, hyphensDict) => (<>
  <span
    className="bti-ranking-table__header-title"
    dangerouslySetInnerHTML={{ __html: betterHyphens(criterium.name, hyphensDict) }}
  />
  {criterium.legendIndex && (
    <span className="bti-ranking-table__legend-index-badge bti-ranking-table__header-legend-index-badge">
      {criterium.legendIndex}
    </span>
  )}
</>);

const Table = ({ tableData, criteria, hyphensDict }) => {
  const [sortBy, setSortBy] = useState({ key: 'rank', order: SortOrder.ASC });
  const [tooltipText, setTooltipText] = useState();

  const sortedData = useMemo(
    () => sortArrayByKey(tableData, sortBy.key, sortBy.order === SortOrder.DESC),
    [sortBy],
  );

  const criteriumHeaderTitles = useMemo(() => {
    const titles = {};
    Object.values(criteria).forEach((criterium) => {
      titles[criterium.REF] = criteriumHeaderTitle(criterium, hyphensDict);
    });
    return titles;
  }, []);

  const scrollBarWidth = useMemo(() => getScrollBarWidth('bti-ranking-table__scrollbar-test'), []);

  const isWide = useMediaQuery({
    query: '(min-width: 800px)',
  });

  return (
    <>
      <Tooltip text={tooltipText} />
      <div className="bti-ranking-table__table-container"
        data-tip
        data-for="bti-ranking-table-tooltip"
        data-iscapture="true"
      >
        <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            classPrefix="bti-ranking-table__table"
            data={sortedData}
            maxHeight={height}
            width={width}
            fixed
            headerHeight={70}
            sortBy={sortBy}
            onColumnSort={setSortBy}
            components={{
              TableCell: Cell,
            }}
            headerCellProps={() => ({
              onMouseEnter: () => { setTooltipText(undefined); },
            })}
            cellProps={({ column, rowData }) => ({
              onMouseEnter: () => {
                setTooltipText(
                  column.cellParams
                  && column.cellParams.tipField
                  && rowData[column.cellParams.tipField],
                );
              },
            })}
            getScrollbarSize={() => scrollBarWidth || (isFirefox ? 0 : 10)}
          >
            <Column
              key='rank'
              dataKey='rank'
              title={t('Rang')}
              frozen={isWide ? 'left' : false}
              width={90}
              minWidth={90}
              sortable
            />
            <Column
              key='name'
              dataKey='name'
              title={t('Land')}
              cellComponent={CountryNameCell}
              frozen={isWide ? 'left' : false}
              width={170}
              minWidth={170}
              sortable
            />
            <Column
              key='index'
              dataKey='index'
              title={t('Wert')}
              cellComponent={IndexCell}
              cellParams={{
                categoryField: 'index_CAT',
                colorField: 'index_color',
                tipField: 'index_tip',
              }}
              width={170}
              minWidth={170}
              sortable
            />

            {Object.values(criteria).map(criterium => (<Column
              key={`criterium_${criterium.REF}`}
              dataKey={`criterium_${criterium.REF}`}
              title={criteriumHeaderTitles[criterium.REF]}
              cellComponent={CriteriumCell}
              cellParams={{
                categoryField: `criterium_${criterium.REF}_CAT`,
                colorField: `criterium_${criterium.REF}_color`,
                tipField: `criterium_${criterium.REF}_tip`,
              }}
              width={160}
              minWidth={160}
              sortable
            />))}

          </BaseTable>
        )}
        </AutoResizer>
      </div>
    </>
  );
};

export default Table;

import React from 'react';
import BTIDashboardTooltip from '../../../../molecules/bti-dashboard-tooltip/BTIDashboardTooltip';

const SelectDimensionTooltip = ({ id, text }) => (
  <BTIDashboardTooltip
    id={id || 'select-dimension-tooltip'}
    effect="solid"
  >
    <div className="bti-map-select-dimension-tooltip">
      <div className="bti-map-select-dimension-tooltip__body">
        {text}
      </div>
    </div>
  </BTIDashboardTooltip>
);

export default SelectDimensionTooltip;

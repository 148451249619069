import React from 'react';
import classNames from 'classnames';
import Icon from '../../../../_particles/icon/Icon';

const DimensionSelectButton = ({
  title,
  active,
  inactive,
  onClick,
}) => (
  <button onClick={onClick} className={classNames('bti-map-dimension-select-button', {
    'bti-map-dimension-select-button--active': active,
    'bti-map-dimension-select-button--inactive': inactive,
  })}>
    {title}
    <span className="bti-map-dimension-select-button__close-icon">
      <Icon icon="close" />
    </span>
  </button>
);

export default DimensionSelectButton;

/**
 * Example:

    const EXAMPLE_MAP = {
      longName: {
        short: 'x',
        type: 'array',
      },
    };

    storeHashData({ longVariableName: [1,2,3] }, EXAMPLE_MAP);

    const { longName } = loadHashData(EXAMPLE_MAP);

 */

export function storeHashData(map, data) {
  const sets = [];

  Object.keys(data).forEach((key) => {
    const value = data[key];
    const { short, type } = map[key];
    if (!short || typeof value === 'undefined' || value === null || !value.length) {
      return;
    }
    if (type === 'array' && Array.isArray(value) && value.length) {
      sets.push(`${short}:${value.join(',')}`);
    } else {
      sets.push(`${short}:${value}`);
    }
  });

  const hash = sets.length ? `#!${sets.join(';')}` : '#';
  if (hash !== document.location.hash && !(hash.length < 2 && document.location.hash < 2)) {
    window.history.replaceState({}, '', hash);
  }
}

export function loadHashData(map) {
  const data = {};

  const reverseMap = {};
  Object.keys(map).forEach((long) => {
    if (map[long].type === 'array') {
      data[long] = [];
    }
    reverseMap[map[long].short] = {
      ...map[long],
      long,
    };
  });

  if (document.location.hash.substr(0, 2) !== '#!') {
    return data;
  }

  document.location.hash.substr(2).split(';').forEach((dataSet) => {
    const [short, value] = dataSet.split(':');
    if (value && value.length) {
      if (reverseMap[short].type === 'array') {
        data[reverseMap[short].long] = value.split(',');
      } else {
        data[reverseMap[short].long] = value;
      }
    }
  });

  return data;
}
